import React, { FC, useRef, useEffect, useState } from 'react'
import { Modal, ModalProps } from './Modal'
import styled from '@xstyled/styled-components'

import { Flex } from 'components'
import { ChevronLeft, ChevronRight } from 'icons'
import { getAspectRatio, x } from 'helpers'
import { IModalMedia, TAspectRatio } from 'types'

interface MediaModalProps extends ModalProps {
  media: IModalMedia
  onPreviousClick?: () => void
  onNextClick?: () => void
  previousDisabled?: boolean
  nextDisabled?: boolean
  mediaAspectRatio?: TAspectRatio
  showMediaOnly?: boolean
}

const NextPrevButton = styled.buttonBox`
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #111;
  user-select: none;
  outline: none;
  margin: 0 0.5rem;
  &:disabled {
    opacity: 0.4;
  }
  &:focus {
    outline: none;
  }
`

export const MediaModal: FC<MediaModalProps> = ({
  isOpen,
  setIsOpen,
  onNextClick,
  onPreviousClick,
  previousDisabled,
  nextDisabled,
  media,
  mediaAspectRatio,
  showMediaOnly,
  ...props
}) => {
  const mediaContainerRef = useRef<HTMLDivElement | null>(null)
  const [modalWidth, setModalWidth] = useState<number>(900)

  const handleResize = () => {
    if (mediaContainerRef?.current?.offsetWidth) {
      setModalWidth(mediaContainerRef?.current?.offsetWidth)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} {...props}>
      <Flex h='100%' w='100%' flexDirection='column'>
        <x.div
          ref={mediaContainerRef}
          w='100%'
          h={{
            xs:
              mediaAspectRatio && !media.ignoreAspectRatio
                ? `calc(${getAspectRatio(mediaAspectRatio)} * 90vw)`
                : 'auto',
            sm:
              mediaAspectRatio && !media.ignoreAspectRatio
                ? `calc(${getAspectRatio(mediaAspectRatio)} * ${modalWidth}px)`
                : 'auto'
          }}
          minHeight={{
            xs:
              mediaAspectRatio && !media.ignoreAspectRatio
                ? `calc(${getAspectRatio(mediaAspectRatio)} * 90vw)`
                : 'auto',
            sm:
              mediaAspectRatio && !media.ignoreAspectRatio
                ? `calc(${getAspectRatio(mediaAspectRatio)} * ${modalWidth}px)`
                : 'auto'
          }}
        >
          {media.image && media.image}
          {media.video && media.video}
        </x.div>
        {!showMediaOnly && (
          <Flex
            w='100%'
            flexDirection={{ xs: 'column', sm: 'row' }}
            p={{ xs: '1rem', sm: '2rem' }}
            backgroundColor='#fff'
            alignItems='center'
          >
            {(media.title || media.description) && (
              <x.div w={{ xs: '100%', sm: '80%' }}>
                {media.title && (
                  <x.h1 fontFamily='proxima' fontSize='1rem' fontWeight='700' color='#111'>
                    {media.title}
                  </x.h1>
                )}
                {media.description && (
                  <x.p
                    fontFamily='proxima'
                    fontSize='1rem'
                    color='#818D8D'
                    dangerouslySetInnerHTML={{ __html: media.description }}
                  />
                )}
              </x.div>
            )}

            {(onPreviousClick || onNextClick) && (
              <Flex
                w={{ xs: '100%', sm: '20%' }}
                justifyContent={{ xs: 'space-between', sm: 'flex-end' }}
                marginTop={{ xs: '1rem', sm: 0 }}
              >
                {onPreviousClick && (
                  <NextPrevButton onClick={() => onPreviousClick()} disabled={previousDisabled}>
                    <ChevronLeft />
                  </NextPrevButton>
                )}
                {onNextClick && (
                  <NextPrevButton onClick={() => onNextClick()} disabled={nextDisabled}>
                    <ChevronRight />
                  </NextPrevButton>
                )}
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Modal>
  )
}
