import React, { FC } from 'react'
import ReactSelect, { OptionsType, OptionTypeBase } from 'react-select'

import { x } from 'helpers'
import { Flex } from '../layout'

interface SelectProps {
  label: string
  name: string
  options: OptionsType<OptionTypeBase>
  onChange: (val: any) => void
  error?: string
  containerStyles: any
  labelColor?: string
}

export const Select: FC<SelectProps> = ({
  label,
  name,
  options,
  onChange,
  error,
  containerStyles = {},
  labelColor
}) => {
  return (
    <Flex flexDirection='column' col={{ xs: 1, md: 1 / 2 }} {...containerStyles}>
      <x.label fontSize='14px' mb='4px' color={labelColor || '#EEE'}>
        {label}
      </x.label>
      <ReactSelect
        defaultValue={options[0]}
        name={name}
        options={options}
        onChange={({ value }) => onChange({ target: { value, name } })}
        styles={{
          container: provided => ({
            ...provided,
            width: '100%'
          }),
          control: (provided, state) => ({
            ...provided,
            height: '50px',
            borderRadius: 0,
            borderColor: state.isFocused ? '#818D8D' : '#818D8D',
            boxShadow: state.isFocused ? 'none' : 'none',
            '&:hover': {
              borderColor: state.isFocused ? '#818D8D' : '#818D8D'
            }
          }),
          singleValue: provided => ({
            ...provided,
            fontSize: '14px',
            color: '#818D8D'
          }),
          menuList: provided => ({
            ...provided,
            fontSize: '14px',
            color: '#818D8D'
          }),
          valueContainer: provided => ({
            ...provided,
            paddingLeft: '20px'
          }),
          indicatorSeparator: provided => ({
            ...provided,
            display: 'none'
          }),
          indicatorsContainer: provided => ({
            ...provided,
            '& svg': {
              fill: 'black'
            }
          })
        }}
      />
      {error ? (
        <x.span fontSize='10px' pt='2px' color='error'>
          {error}
        </x.span>
      ) : null}
    </Flex>
  )
}
