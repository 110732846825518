import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const Check: FC<IconProps> = ({ width = 63, height = 60, ...props }) => {
  return (
    <x.svg width={width} height={height} viewBox='0 0 84 79' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#filter0_d)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54 32.7928L38.4467 49L30 40.1982L32.6801 37.4054L38.4467 43.4145L51.3199 30L54 32.7928Z'
          fill='url(#paint0_linear)'
        />
        <path
          d='M32.6801 38.1276L38.0859 43.7607L38.4467 44.1366L38.8074 43.7607L51.3199 30.7221L53.307 32.7928L38.4467 48.2779L30.693 40.1982L32.6801 38.1276Z'
          stroke='url(#paint1_linear)'
        />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='0'
          y='0'
          width='84'
          height='79'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset />
          <feGaussianBlur stdDeviation='15' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.219608 0 0 0 0 0.956863 0 0 0 0 0.945098 0 0 0 0.5 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
        <linearGradient id='paint0_linear' x1='42' y1='30' x2='42' y2='49' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#38F4F1' />
          <stop offset='1' stopColor='#009592' />
        </linearGradient>
        <linearGradient id='paint1_linear' x1='42' y1='30' x2='42' y2='49' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#38F4F1' />
          <stop offset='1' stopColor='#38F4F1' stopOpacity='0' />
        </linearGradient>
      </defs>
    </x.svg>
  )
}
