import React, { FC, useEffect } from 'react'
import { useMatch } from '@reach/router'
import { Toaster } from 'react-hot-toast'
// components
import { x } from 'helpers'
import { Footer, NavBar, UniqueApproach } from './layout'
import { RequestDemoForm } from './forms'
import { Drawer } from './drawers'
import { useAppState } from '../providers'

export const AppContainer: FC = ({ children }) => {
  const hideUniqueApproach = useMatch('/faq') || useMatch('/contact')
  const {
    setDrawerContent,
    appState: { drawerContent }
  } = useAppState()

  useEffect(() => {
    setDrawerContent(null)
  }, [children])

  return (
    <>
      <Toaster />
      <NavBar />
      <Drawer>
        {drawerContent?.title.toLowerCase().includes('demo') && (
          <>
            <x.p fontSize='14px' mb='20px'>
              Got a project you want to discuss?
              <br />
              Send us a message, we’d love to talk.
            </x.p>
            <RequestDemoForm labelColor='#000' />
          </>
        )}
      </Drawer>
      {children}
      {hideUniqueApproach ? null : <UniqueApproach />}
      <Footer />
    </>
  )
}
