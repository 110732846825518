import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { IGalleryImage, x } from 'helpers'
import { IModalMedia, ITemplateSectionInfo } from 'types'
import { Text, Video } from 'components'

export const FoundryImages: IGalleryImage[] = [
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry1.png'
        alt='Foundry 1'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
        className='no-drag'
      />
    ),
    modal: {
      title: 'Foundry',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry1.png'
          alt='Foundry 1'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry2.png'
        alt='Foundry 2'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
        className='no-drag'
      />
    ),
    modal: {
      title: 'Foundry',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry2.png'
          alt='Foundry 2'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry3.png'
        alt='Foundry 3'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
        className='no-drag'
      />
    ),
    modal: {
      title: 'Foundry',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry3.png'
          alt='Foundry 3'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry4.png'
        alt='Foundry 4'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
        className='no-drag'
      />
    ),
    modal: {
      title: 'Foundry',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry4.png'
          alt='Foundry 4'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry5.png'
        alt='Foundry 5'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
        className='no-drag'
      />
    ),
    modal: {
      title: 'Foundry',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry5.png'
          alt='Foundry 5'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry6.png'
        alt='Foundry 6'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
        className='no-drag'
      />
    ),
    modal: {
      title: 'Foundry',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry6.png'
          alt='Foundry 6'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry7.png'
        alt='Foundry 7'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
        className='no-drag'
      />
    ),
    modal: {
      title: 'Foundry',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry7.png'
          alt='Foundry 7'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  }
]

export const FoundryVideo: IModalMedia = {
  title: 'Foundry',
  video: <Video url='https://d27pgjik7gkkwi.cloudfront.net/templates/foundryWalkthrough.mp4' light='https://d27pgjik7gkkwi.cloudfront.net/templates/foundry1.png'/>
}

export const FoundryInfo: ITemplateSectionInfo = {
  title: 'Foundry',
  titleNumber: '01',
  description: (
    <Text color='mvrkLightGray' fontSize='16px'>
      Inspired by rooftop venue space and the collaboration fostered by an open concept, <strong>Foundry</strong>{' '}
      embodies everything that is open and organic whether in thought, action, or placement. In this space your brand
      experience is full view through seven pre-set content areas, and presented in such a way that people want to
      engage and explore more. <br />
      <br />
      <x.span color='white' fontWeight='bold'>
        Suggested uses:
      </x.span>{' '}
      Product releases and showcases, announcements and general company/industry events.
    </Text>
  ),
  placeholder: (
    <StaticImage
      src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundryPlaceholder.png'
      alt='Foundry Placeholder'
      layout='fixed'
      width={490}
      height={320}
      style={{ width: '490px' }}
    />
  ),
  placeholderMobile: (
    <StaticImage
      src='https://d27pgjik7gkkwi.cloudfront.net/templates/foundryPlaceholder.png'
      alt='Foundry Placeholder'
      layout='constrained'
      style={{ width: '100%' }}
    />
  ),
  images: FoundryImages,
  video: FoundryVideo
}
