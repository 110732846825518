import React, { FC, forwardRef } from 'react'
import { SystemProps } from '@xstyled/styled-components'

import { x } from 'helpers'

interface FlexProps extends SystemProps {
  centered?: boolean
  column?: boolean
  className?: string
  onClick?: () => void
}

export const Flex: FC<FlexProps> = forwardRef(({ centered, column, className, onClick, ...props }, ref) => {
  return (
    <x.section
      display='flex'
      className={className}
      onClick={() => onClick?.()}
      flexDirection={column ? 'column' : ''}
      justifyContent={centered ? 'center' : 'initial'}
      alignItems={centered ? 'center' : 'initial'}
      {...props}
      {...(ref ? { ref } : {})}
    />
  )
})
