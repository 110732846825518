import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { IGalleryImage, x } from 'helpers'
import { IModalMedia, ITemplateSectionInfo } from 'types'
import { Text, Video } from 'components'

export const LightboxImages: IGalleryImage[] = [
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox1.png'
        alt='Lightbox 1'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Lightbox',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox1.png'
          alt='Lightbox 1'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox2.png'
        alt='Lightbox 2'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Lightbox',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox2.png'
          alt='Lightbox 2'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox3.png'
        alt='Lightbox 3'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Lightbox',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox3.png'
          alt='Lightbox 3'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox4.png'
        alt='Lightbox 4'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Lightbox',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox4.png'
          alt='Lightbox 4'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox5.png'
        alt='Lightbox 5'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Lightbox',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox5.png'
          alt='Lightbox 5'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox6.png'
        alt='Lightbox 6'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Lightbox',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox6.png'
          alt='Lightbox 6'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox7.png'
        alt='Lightbox 7'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Lightbox',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox7.png'
          alt='Lightbox 7'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  }
]

export const LightboxVideo: IModalMedia = {
  title: 'Lightbox',
  video: (
    <Video
      url='https://d27pgjik7gkkwi.cloudfront.net/templates/lightboxWalkthrough.mp4'
      light='https://d27pgjik7gkkwi.cloudfront.net/templates/lightbox1.png'
    />
  )
}

export const LightboxInfo: ITemplateSectionInfo = {
  title: 'Lightbox',
  titleNumber: '02',
  description: (
    <Text color='mvrkLightGray' fontSize='16px'>
      Inspired by innovation centers and the gleaming titanium of state-of-the-art devices, <strong>Lightbox</strong>{' '}
      exudes a contemporary feel. Illuminating your brand at the forefront of each room with anywhere from 8-10 content
      showcase areas throughout the space. Thereby instilling confidence and the idea that your experience and its
      messaging is top of the line and desirable. <br />
      <br />
      <x.span color='white' fontWeight='bold'>
        Suggested uses:
      </x.span>{' '}
      Conferences, meetings, video-forward and highly-visual programming
    </Text>
  ),
  placeholder: (
    <StaticImage
      src='https://d27pgjik7gkkwi.cloudfront.net/templates/lightboxPlaceholder.png'
      alt='Lightbox Placeholder'
      layout='constrained'
      style={{ width: '100%' }}
    />
  ),
  images: LightboxImages,
  video: LightboxVideo
}
