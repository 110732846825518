import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const InstagramIcon: FC<IconProps> = ({ width = 20, height = 23, fill = 'white', ...props }) => {
  return (
    <x.svg width={width} height={height} viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0)'>
        <path
          d='M10.0044 6.28809C7.16512 6.28809 4.87494 8.57581 4.87494 11.412C4.87494 14.2483 7.16512 16.536 10.0044 16.536C12.8437 16.536 15.1339 14.2483 15.1339 11.412C15.1339 8.57581 12.8437 6.28809 10.0044 6.28809ZM10.0044 14.7433C8.16959 14.7433 6.66958 13.2494 6.66958 11.412C6.66958 9.57474 8.16512 8.08081 10.0044 8.08081C11.8437 8.08081 13.3392 9.57474 13.3392 11.412C13.3392 13.2494 11.8392 14.7433 10.0044 14.7433ZM16.5401 6.07849C16.5401 6.74296 16.0044 7.27364 15.3437 7.27364C14.6785 7.27364 14.1473 6.7385 14.1473 6.07849C14.1473 5.41849 14.683 4.88335 15.3437 4.88335C16.0044 4.88335 16.5401 5.41849 16.5401 6.07849ZM19.9375 7.29148C19.8616 5.69052 19.4955 4.2724 18.3214 3.10401C17.1517 1.93562 15.7321 1.56994 14.1294 1.48967C12.4776 1.39602 7.52673 1.39602 5.87494 1.48967C4.27672 1.56548 2.85708 1.93116 1.68297 3.09955C0.50886 4.26794 0.147252 5.68606 0.0668946 7.28702C-0.0268555 8.93703 -0.0268555 13.8826 0.0668946 15.5326C0.142788 17.1336 0.50886 18.5517 1.68297 19.7201C2.85708 20.8885 4.27226 21.2542 5.87494 21.3344C7.52673 21.4281 12.4776 21.4281 14.1294 21.3344C15.7321 21.2586 17.1517 20.8929 18.3214 19.7201C19.491 18.5517 19.8571 17.1336 19.9375 15.5326C20.0312 13.8826 20.0312 8.94149 19.9375 7.29148ZM17.8035 17.303C17.4553 18.1771 16.7812 18.8505 15.9017 19.2028C14.5848 19.7246 11.4598 19.6041 10.0044 19.6041C8.54905 19.6041 5.41958 19.7201 4.10708 19.2028C3.23208 18.8549 2.55797 18.1816 2.20529 17.303C1.68297 15.9875 1.8035 12.8658 1.8035 11.412C1.8035 9.95825 1.68743 6.83215 2.20529 5.52106C2.55351 4.64699 3.22761 3.97361 4.10708 3.62131C5.42405 3.09955 8.54905 3.21995 10.0044 3.21995C11.4598 3.21995 14.5892 3.10401 15.9017 3.62131C16.7767 3.96915 17.4509 4.64253 17.8035 5.52106C18.3259 6.83661 18.2053 9.95825 18.2053 11.412C18.2053 12.8658 18.3259 15.992 17.8035 17.303Z'
          fill='#38F4F1'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width={width} height={height} fill={fill} />
        </clipPath>
      </defs>
    </x.svg>
  )
}
