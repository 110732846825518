import React, { FC, useState, useEffect } from 'react'
import { SystemProps } from '@xstyled/styled-components'
import StyledReactModal, { BaseModalBackground } from 'styled-react-modal'
import { CloseIcon } from 'icons'

import { getAspectRatio, styled } from 'helpers'
import { TAspectRatio } from 'types'

export interface ModalProps extends SystemProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  onClose?: () => void
  aspectRatio?: TAspectRatio
  maxWidth?: string
}

// @ts-ignore
export const FadeInModalBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: all 0.3s ease-in-out;
`

const StyledModal = StyledReactModal.styled`
  position: relative;
  width: 90vw;
  max-width: ${props => props.maxWidth};
  height: ${props => (props.aspectRatio ? `calc(${props.aspectRatio} * 90vw)` : 'initial')};
  min-height: ${props => (props.aspectRatio ? `calc(${props.aspectRatio} * 90vw)` : 'initial')};
  max-height: ${props => (props.aspectRatio ? `calc(${props.aspectRatio} * 900px)` : 'initial')};
  color: black;
  background-color: #000;
  opacity: ${props => props.opacity};
  transition: all 0.3s ease-in-out;
`

const CloseButton = styled.buttonBox`
  height: 36px;
  width: 36px;
  position: absolute;
  z-index: 31;
  top: 10px;
  right: 10px;
  background-color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  user-select: none;
`

export const Modal: FC<ModalProps> = ({ isOpen, setIsOpen, children, aspectRatio, maxWidth, onClose, ...props }) => {
  const [opacity, setOpacity] = useState<number>(0)

  useEffect(() => {
    setIsOpen(isOpen)
  }, [isOpen])

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1)
    }, 100)
  }

  const beforeClose = () => {
    return new Promise(resolve => {
      setOpacity(0)
      setTimeout(resolve, 300)
    })
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeyDown={toggleModal}
      opacity={opacity}
      backgroundProps={{ opacity }}
      beforeClose={beforeClose}
      afterOpen={afterOpen}
      afterClose={() => onClose?.()}
      aspectRatio={aspectRatio ? getAspectRatio(aspectRatio) : ''}
      backgroundColor='white'
      maxWidth={maxWidth || '900px'}
      {...props}
    >
      <CloseButton onClick={toggleModal}>
        <CloseIcon />
      </CloseButton>
      {children}
    </StyledModal>
  )
}
