import React, { FC } from 'react'

import { FeatureCardSection } from 'components'
import { featureCards } from 'helpers'

interface ItsAllHereProps {
  isMobile: boolean
}

export const ItsAllHere: FC<ItsAllHereProps> = ({ isMobile }) => {
  return (
    <FeatureCardSection
      isMobile={isMobile}
      number='01'
      title='IT’S ALL HERE'
      description='From templated environments to core features and capabilities, we have everything you need and more to
          activate a virtual event.'
      featureCards={featureCards}
      pb='150px'
    />
  )
}
