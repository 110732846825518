import React, { FC } from 'react'
import { SystemProps } from '@xstyled/styled-components'

import { x } from 'helpers'
import { Heading, Text } from 'components'
import { FeatureCircleIcon, } from 'icons'

interface FeatureCardProps extends SystemProps {
  card: {
    title: string
    description: string
    icon: string
  }
  index?: number
  onClick?: () => void
}

export const FeatureCard: FC<FeatureCardProps> = ({ card, index, onClick, ...props }) => {
  let hasRightMargin = false
  let hasBottomMargin = false
  if (typeof index === 'number') {
    hasRightMargin = (index + 1) % 3 !== 0
    hasBottomMargin = index < 3
  }
  return (
    <x.div
      w={{ _: '240px', sm: 'calc(33.5% - 17px)' }}
      maxWidth={{ _: '240px', md: '340px' }}
      h={{ _: '338px', sm: 'auto' }}
      maxHeight={{ _: '338px', sm: '400px', md: '364px' }}
      minHeight={{ _: '338px', sm: '364px' }}
      border='1px solid'
      borderImage='linear-gradient(180deg, #687474 0%, rgba(104, 116, 116, 0) 100%) 1'
      display='flex'
      flexDirection='column'
      alignItems='center'
      background='linear-gradient(137.96deg, #242626 0%, #1B1D1D 100%)'
      px={{ _: '20px', md: '2%' }}
      pt={{ _: '30px', md: '44px' }}
      pb={{ _: '10px' }}
      transition='all 0.25s ease-in-out'
      mt={{ hover: { _: 0, md: '-20px' } }}
      cursor={{ hover: onClick ? 'pointer' : 'default' }}
      {...(hasRightMargin ? { mr: '17' } : {})}
      {...(hasBottomMargin ? { mb: '30px' } : {})}
      {...props}
      onClick={() => onClick?.()}
    >
      <FeatureCircleIcon>
        {card.icon ? (
          <x.img
            src={`https://d27pgjik7gkkwi.cloudfront.net/icons/${card.icon}`}
            alt={card.icon}
            zIndex={{ _: 1 }}
            w={{ _: 'auto' }}
            h={{ _: '100%', sm: 'auto' }}/>
        ) : null}
      </FeatureCircleIcon>
      <Heading w='100%' as='h3' fontWeight='900' fontSize='18px' mb='10px'>
        {card.title}
      </Heading>
      <Text fontSize='14px' color='mvrkLightGray' dangerouslySetInnerHTML={{ __html: card.description }} />
    </x.div>
  )
}
