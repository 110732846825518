import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const ChevronLeft: FC<IconProps> = ({ width = 10, height = 16, fill = '#818D8D', ...props }) => {
  return (
    <x.svg width={width} height={height} viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <x.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.42525 16L10 14.4302L3.54951 8L10 1.5698L8.42525 -6.88346e-08L0.4 8L8.42525 16Z'
        fill={fill}
      />
    </x.svg>
  )
}
