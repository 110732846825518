import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const CloseIcon: FC<IconProps> = ({ size = 18, fill = 'white', ...props }) => {
  return (
    <x.svg width={size} height={size} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2857 9L18 16.74L16.7143 18L9 10.26L1.28571 18L0 16.74L7.71429 9L0 1.26L1.28571 0L9 7.74L16.7143 0L18 1.26L10.2857 9Z'
        fill={fill}
      />
    </x.svg>
  )
}
