import React, { FC } from 'react'

import { FeatureCardSection } from 'components'
import { featureCards } from 'helpers'

interface MasterFeaturesProps {
  isMobile: boolean
}

export const MasterFeatures: FC<MasterFeaturesProps> = ({ isMobile }) => {
  return (
    <FeatureCardSection
      isMobile={isMobile}
      number='04'
      title='MASTER FEATURES'
      description='Further enhancements to the event experience includes a library of features that bring the event and environments to life.'
      featureCards={featureCards}
      pt='10px'
    />
  )
}
