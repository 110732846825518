import { useState, useEffect } from 'react'
import { throttle } from 'lodash'

export const useScrollTop = () => {
  const [isTop, setIsTop] = useState<boolean>(true)

  const handleScroll = throttle(() => {
    if (window.scrollY > 0) {
      setIsTop(false)
    } else {
      setIsTop(true)
    }
  }, 100)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return isTop
}
