import React, { FC } from 'react'
import styled from '@xstyled/styled-components'

interface HiddenTextProps {
  as: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const Hidden = styled.divBox`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export const HiddenText: FC<HiddenTextProps> = ({ as, children }) => {
  return <Hidden as={as}>{children}</Hidden>
}
