import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { x } from 'helpers'

export const MultiDeviceImage = () => (
  <x.img src='https://d27pgjik7gkkwi.cloudfront.net/multiDevice.png' alt='Multi Device' h={{_: '100%'}} w={{_: '100%'}}/>
)

export const Powerful3DImage = () => (
  <x.img src='https://d27pgjik7gkkwi.cloudfront.net/powerful3D.png' alt='Powerful 3D' h={{_: '100%'}} w={{_: '100%'}}/>
)

export const ExtendedRealityImage = () => (
  <x.img src='https://d27pgjik7gkkwi.cloudfront.net/extendedReality.png' alt='Extended Reality' h={{_: '100%'}} w={{_: '100%'}}/>
)

export const LaptopGamingImage = () => (
  <x.img src='https://d27pgjik7gkkwi.cloudfront.net/laptopGaming.png' alt='Laptop Gaming' h={{_: '100%'}} w={{_: '100%'}}/>
)

export const ModalsImage = () => (
  <x.img src='https://d27pgjik7gkkwi.cloudfront.net/modals.png' alt='User Journey' h={{_: '100%'}} w={{_: '100%'}}/>
)

export const TabletMobileImage = () => (
  <x.img src='https://d27pgjik7gkkwi.cloudfront.net/tabletMobile.png' alt='Tablet Mobile' h={{_: '100%'}} w={{_: '100%'}}/>
)
