import React, { FC, useState, ReactNode } from 'react'
import styled from '@xstyled/styled-components'

import { Flex } from 'components'
import { x } from 'helpers'

interface FaqProps {
  question: string
  answer: string | ReactNode
}

const PlusMinusIcon = styled.divBox`
  position: relative;
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 1rem;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #38f4f1;
    transition: transform 0.25s ease-in-out;
  }
  &:before {
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
  }
  &:after {
    top: 50%;
    left: 0;
    height: 2px;
    width: 100%;
    margin-top: -1px;
  }
  &:hover {
    cursor: pointer;
  }
  &.open {
    &:before {
      transform: rotate(90deg);
    }
    &:after {
      transform: rotate(180deg);
    }
  }
`

const ExpandingSection = styled.divBox`
  overflow: ${props => (props.open ? 'auto' : 'hidden')};
  width: 100%;
  max-height: ${props => (props.open ? '150px' : '0px')};
  transition: max-height 0.25s ease-in-out;
`

export const Faq: FC<FaqProps> = ({ question, answer, ...props }) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <x.div display='flex' alignItems='center' {...props}>
      <Flex flexDirection='column'>
        <x.div display='flex' onClick={() => setOpen(!open)} cursor={{ hover: 'pointer' }}>
          <PlusMinusIcon className={open ? 'open' : ''} />
          <x.h4 color='#fff' fontSize='1rem' fontFamily='proxima' fontWeight='700'>
            {question}
          </x.h4>
        </x.div>
        <ExpandingSection open={open}>
          <x.div padding='1rem 0 1rem 2rem'>
            <x.p color='mvrkLightGray' fontSize='0.875rem' fontFamily='proxima' fontWeight='400'>
              {answer}
            </x.p>
          </x.div>
        </ExpandingSection>
      </Flex>
    </x.div>
  )
}
