import React, { FC } from 'react'
import { SystemProps } from '@xstyled/styled-components'

import { Flex } from 'components'
import { x } from 'helpers'

export const FeatureCircleIcon: FC<SystemProps> = ({ children }) => (
  <Flex
    position='relative'
    justifyContent='center'
    alignItems='center'
    w={{ _: '100px', sm: '120px', md: '150px' }}
    h={{ _: '100px', sm: '120px', md: '150px' }}
    mb={{ _: '30px' }}
  >
    <Flex
      w={{ _: '100px', sm: '120px', md: '150px' }}
      h={{ _: '100px', sm: '120px', md: '150px' }}
      borderRadius='50%'
      background='linear-gradient(rgba(104, 116, 116, 0) 0%, rgba(104, 116, 116, 0.3) 100%)'
      zIndex='0'
      top='0'
      left='0'
      position='absolute'
      justifyContent='center'
      alignItems='center'
    >
      <x.div
        w={{ _: '98px', sm: '118', md: '148px' }}
        h={{ _: '98px', sm: '118', md: '148px' }}
        ml='1px'
        mb='1px'
        borderRadius='50%'
        background='rgba(17, 17, 17, 0.7)'
        position='absolute'
        top='0px'
        left='0'
        zIndex='0'
      />
    </Flex>
    {children}
  </Flex>
)
