import React from 'react'
import { ThemeProvider, Preflight } from '@xstyled/styled-components'
import { ModalProvider } from 'styled-react-modal'

import { AppStateProvider } from 'providers'
import { AppContainer, FadeInModalBackground } from 'components'
// helpers
import { theme } from 'helpers'
// fonts
import '@fontsource/anonymous-pro/400.css'
import '@fontsource/metropolis'
import './fonts/index.css'
import './styles/styles.scss'

export const wrapRootElement = ({ element }) => (
  <AppStateProvider>
    <ThemeProvider theme={theme}>
      <ModalProvider backgroundComponent={FadeInModalBackground}>{element}</ModalProvider>
    </ThemeProvider>
  </AppStateProvider>
)

export const wrapPageElement = ({ element, props }) => (
  <AppContainer {...props}>
    <Preflight />
    {element}
  </AppContainer>
)
