import React, { FC } from 'react'

import { x } from 'helpers'
import { Flex, InstagramIcon, LinkedInIcon, MVRKLogo } from 'components'
import { SystemProps } from '@xstyled/system'

export const Footer = () => (
  <Flex
    h={{ _: 'auto', md: '364px' }}
    w='100%'
    flexDirection='column'
    alignItems='center'
    pt={{ _: '40px', md: '100px' }}
    pb={{ _: '30px' }}
    backgroundColor='#000'
    px={{ _: '2rem', md: '0' }}
  >
    <Flex justifyContent='center' alignItems='center' flexDirection='row' flexWrap={{ _: 'wrap', md: 'none' }}>
      <Flex justifyContent='center' alignItems='center' w={{ _: '100%', md: 'auto' }} mb={{ _: '40px', md: 0 }}>
        <MVRKLogo width='100px' height='63px' />
      </Flex>
      <x.section
        w={{ _: '50%', md: 'auto' }}
        ml={{ md: '98px' }}
        mr={{ _: '0px', md: '60px' }}
        mb={{ _: '30px', md: 0 }}
        alignSelf='flex-start'
      >
        <x.h5 fontWeight='bold' mb='18px' fontSize='17px'>
          Orlando HQ
        </x.h5>
        <x.p fontSize='17px'>100 East Pine Street</x.p>
        <x.p fontSize='17px'>Suite 202</x.p>
        <x.p fontSize='17px'>Orlando, FL 32801</x.p>
      </x.section>

      <x.section w={{ _: '50%', md: 'auto' }} mb={{ _: '30px', md: 0 }} mr={{ md: '60px' }} alignSelf='flex-start'>
        <x.h5 fontWeight='bold' mb='18px' fontSize='17px'>
          New York
        </x.h5>
        <x.p fontSize='17px'>147 Prince Street</x.p>
        <x.p fontSize='17px'>Brooklyn, NY 11201</x.p>
      </x.section>
      <x.section w={{ _: '50%', md: 'auto' }} mb={{ _: '20px', md: 0 }} mr={{ md: '145px' }} alignSelf='flex-start'>
        <x.h5 fontWeight='bold' mb='18px' fontSize='17px'>
          For general inquiries
        </x.h5>
        <x.p fontSize='17px'>
          <x.a href='mailto:hello@mvrk.co' color='white' textDecoration='none'>
            hello@mvrk.co
          </x.a>
        </x.p>
        <x.p fontSize='17px'>
          <x.a href='tel:4077769107' target='_blank' rel='noopener noreferrer' color='white' textDecoration='none'>
            (407) 776-9107
          </x.a>
        </x.p>
      </x.section>
      <Flex flex={1} w={{ _: '50%', md: 'auto' }} alignSelf={{ _: 'flex-start', md: 'none' }} mb={{ _: '20px', md: 0 }}>
        <x.div mr='4px'>
          <a href='https://www.linkedin.com/company/mvrk/' target='_blank'>
            <LinkedInIcon />
          </a>
        </x.div>
        <x.div ml='4px'>
          <a href='https://www.instagram.com/mvrkgroup/' target='_blank'>
            <InstagramIcon />
          </a>
        </x.div>
      </Flex>
    </Flex>
    <Flex
      justifyContent='center'
      alignItems={{ _: 'center', md: 'flex-end' }}
      flexDirection={{ _: 'column', md: 'row' }}
      flexWrap={{ _: 'wrap', md: 'none' }}
      minHeight={{ _: 'auto', md: '80px' }}
      w={{ _: '100%', md: 'auto' }}
    >
      <x.section ml={{ md: '64px' }} mr={{ _: '0', md: '60px' }} mb={{ _: '30px', md: 0 }}>
        <FooterLink
          as='a'
          target='_blank'
          href='https://d27pgjik7gkkwi.cloudfront.net/documents/vx360-privacy-policy.pdf'
        >
          Terms &amp; Conditions
        </FooterLink>
      </x.section>
      <x.section mr={{ md: '0px' }} ml={{ md: '8px' }} mb={{ _: '30px', md: 0 }}>
        <FooterLink as='a' target='_blank' href='https://d27pgjik7gkkwi.cloudfront.net/documents/vx360-sla.pdf'>
          Service Level Agreement
        </FooterLink>
      </x.section>
      <x.section ml={{ _: '0', md: '40px' }} mb={{ _: '30px', md: 0 }}>
        <FooterLink>&copy; MVRK LLC. All Rights Reserved.</FooterLink>
      </x.section>
    </Flex>
  </Flex>
)

const FooterLink: FC<SystemProps> = props => (
  <x.span color='rgba(255, 255, 255, 0.31)' fontSize='15px' fontWeight='bold' {...props} />
)
