import { useEffect } from 'react'

export function useOnClickOutside(refs: any[], handler) {
  useEffect(() => {
    const listener = event => {
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i]
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
      }

      handler(event)
    }

    window.addEventListener('mousedown', listener)
    window.addEventListener('touchstart', listener)

    return () => {
      window.removeEventListener('mousedown', listener)
      window.removeEventListener('touchstart', listener)
    }
  }, [refs, handler])
}
