import React, { FC, useState } from 'react'
import { useMobileDetect } from 'hooks'

import { Flex } from '../layout'
import { MediaModal } from '../modal'
import { Heading, OutlinedText, Text } from '../typography'
import { PlayButtonIcon, PlusIcon } from 'icons'
import { Button } from '../controls'
import { useAppState } from 'providers'
import { x, DemoVideo, DrawerTypes, DrawerOptions } from 'helpers'

export interface SplashProps {
  desktopImg: string
  mobileImg: string
  alignment: string
  title1: string
  title2: string
  description: string
}

export const Splash: FC<SplashProps> = ({ desktopImg, mobileImg, alignment, title1, title2, description }) => {
  const { setDrawerContent } = useAppState()
  const { isMobile } = useMobileDetect()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const topAligned = alignment === 'top'

  return (
    <>
      <x.section
        w='100%'
        minHeight='450px'
        maxHeight='900px'
        h={{ sm: '100vh', _: '100%' }}
        bg='#111 !important'
        display='flex'
        flexDirection={{ sm: topAligned ? 'column' : 'row', _: 'row' }}
        alignItems={{ sm: topAligned ? 'flex-start' : 'center', _: 'flex-end' }}
      >
        <x.div
          maxWidth={{ _: topAligned ? '1400px' : '1200px' }}
          w='100%'
          h={{ sm: '100%', _: 'auto' }}
          zIndex='0'
          display='flex'
          bg='#111 !important'
          flexDirection={{ sm: topAligned ? 'column' : 'row', _: 'column' }}
          alignItems={{ sm: topAligned ? 'flex-start' : 'center', _: 'flex-end' }}
          justifyContent='center'
          backgroundImage={{ sm: `url(${desktopImg})` }}
          backgroundPosition={{ sm: topAligned ? 'center bottom' : 'center center' }}
          backgroundSize={{ sm: 'contain' }}
          backgroundRepeat='no-repeat'
          m='0 auto'
        >
          <x.img
            display={{ sm: 'none' }}
            h={'auto'}
            w={'100%'}
            src={mobileImg}
            {...(alignment === 'left-padded' ? { pt: { _: '60px' } } : {})}
            alt='Splash Image'
          />
          <Flex
            maxWidth={{ _: topAligned ? '1200px' : '994px' }}
            m='0 auto'
            flexDirection='column'
            flex={1}
            order={{ _: 2, md: 1 }}
            pb={{ xs: '20px' }}
            pr='20px'
            textAlign={{ sm: topAligned ? 'center' : 'left', _: 'center' }}
            alignItems={{ sm: topAligned ? 'center' : 'flex-start', _: 'center' }}
            {...(topAligned && !isMobile
              ? { justifyContent: 'flex-start', pt: { _: '100px', lg: '120px', xl: '160px' } }
              : { pl: { _: '20px' }, mt: { lg: '-30px' } })}
          >
            <Heading color='primary' textTransform='uppercase' fontSize='14px' fontFamily='proxima' fontWeight='bold'>
              <Flex
                mb={{ _: '5px' }}
                mt={{ _: '10px', md: 0 }}
                {...(topAligned && !isMobile ? { flexDirection: 'column', alignItems: 'center' } : {})}
              >
                <PlusIcon
                  mt='2px'
                  mr={{ _: '4px', md: '10px' }}
                  {...(topAligned && !isMobile ? { m: { _: '10px' } } : {})}
                />
                Vx360 3D Platform
              </Flex>
            </Heading>
            <Flex mb={{ _: '5px' }} flexDirection={{ _: topAligned && !isMobile ? 'row' : 'column' }}>
              <OutlinedText
                textTransform='uppercase'
                lineHeight={{ _: '90%' }}
                fontSize={{ _: '36px', md: '64px', lg: '72px' }}
                fontFamily='nimbus'
                fontWeight='900'
                fontStyle='normal'
                mr={{ md: '12px' }}
              >
                {title1}
              </OutlinedText>
              <Heading
                textTransform='uppercase'
                lineHeight={{ _: '90%' }}
                fontSize={{ _: '36px', md: '64px', lg: '72px' }}
                fontFamily='nimbus'
                fontWeight='900'
                fontStyle='normal'
              >
                {title2}
              </Heading>
            </Flex>
            <Text
              color='mvrkLightGray'
              w={{ sm: topAligned ? '75%' : '50%', _: '100%' }}
              maxWidth={{ sm: topAligned ? '75%' : '450px', _: '100%' }}
              mb={{ _: '20px', md: '40px' }}
              fontSize={{ _: '14px', md: '16px' }}
            >
              {description}
            </Text>
            <Flex pb={{ _: '40px', md: 0 }}>
              <Button mr={{ _: '20px' }} onClick={() => setModalOpen(true)}>
                <Flex justifyContent='center' alignItems='center'>
                  Play Video <PlayButtonIcon ml={1} />
                </Flex>
              </Button>
              <Button variant='transparent' onClick={() => setDrawerContent(DrawerOptions[DrawerTypes.demoDrawer])}>
                Request Demo
              </Button>
            </Flex>
          </Flex>
        </x.div>
      </x.section>

      <MediaModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        media={DemoVideo}
        mediaAspectRatio='16:9'
        showMediaOnly
        previousDisabled
        nextDisabled
      />
    </>
  )
}
