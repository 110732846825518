import React from 'react'
import { Link } from 'gatsby'
import { Faqs } from 'components'
import { x, DrawerOptions, DrawerTypes } from 'helpers'
import { useAppState } from 'providers'
import { IFaq } from 'types'

export const FrequentlyAskedQuestions = () => {
  const { setDrawerContent } = useAppState()

  const GeneralSalesQuestions: IFaq[] = [
    {
      question: `When you say "360 degrees" and walkable, what do you mean? How do I maneuver around within the space?`,
      answer: (
        <>
          We mean you can move through the space at your own pace and with the click of a mouse or the tap of a key. We
          recommend{' '}
          <a className='email-link' onClick={() => setDrawerContent(DrawerOptions[DrawerTypes.demoDrawer])}>
            scheduling a demo
          </a>{' '}
          to see how it works for yourself!
        </>
      )
    },
    {
      question: `What is to be expected if I schedule a demo? How long does a demo take?`,
      answer: `Your demo will cover a personalized walkthrough of what our platform offers. In 30 minutes, we'll cover key features, functionality, customizations and general information about how our team will work with yours, to develop and host your event.`
    },
    {
      question: `I’m ready to host an event on Vx360! What is the next step?`,
      answer: (
        <>
          Great! We’re excited to create the future with you on Vx360. Please submit an inquiry to{' '}
          <a href='mailto:hello@mvrk.co' className='email-link'>
            hello@mvrk.co
          </a>{' '}
          or through the{' '}
          <Link to='/contact' className='email-link'>
            contact form
          </Link>
          , and we’ll get down to the nitty gritty details and next steps with you.
        </>
      )
    },
    {
      question: `What is the projected timeline for an event on the platform?`,
      answer: `Depending on the platform package you select, the timeline from kick-off to event is 6 to 8 weeks for templated events. Custom project timelines vary.`
    },
    {
      question: `How much does hosting an event on the Vx360 platform cost?`,
      answer: (
        <>
          <Link to='/pricing' className='email-link'>
            We offer a selection of standardized packages
          </Link>
          . For further details or to discuss what package best fits your needs and budget please{' '}
          <Link to='/contact' className='email-link'>
            contact us
          </Link>{' '}
          !
        </>
      )
    },
    {
      question: `Do you offer multi-event rates?`,
      answer: (
        <>
          Based upon your event request, we’re happy to explore an event series with you. Please{' '}
          <Link to='/contact'>contact us</Link> to discuss further.
        </>
      )
    }
  ]

  const EventFocusedQuestions: IFaq[] = [
    {
      question: `What is the level of customization I can make?`,
      answer: (
        <>
          You can read more about customization{' '}
          <Link to='/explore' className='email-link'>
            on the explore page
          </Link>
          . If customization is a priority, we highly recommend speaking directly with our team about a completely
          unique and from scratch virtual event or experience.
        </>
      )
    },
    {
      question: `Can the platform host an event in different time zones?`,
      answer: `Yes we can. Based on your specific ask or event needs, our team would be happy to discuss how best to approach a multi-time zone event on the platform.`
    },
    {
      question: `Are attendees able to chat with each other during events?`,
      answer: `Vx360 contains a variety of communication tools that include text and video. These tools can be limited depending on your preference.`
    },
    {
      question: `Is there a maximum number of guests I can invite to my event or attendees that the platform can host?`,
      answer: `There is no technical limitation on the number of users who can join an event, but we’d love to discuss expected attendee numbers to advise how to best utilize our sessions features, if applicable.`
    },
    {
      question: `Is the platform capable of multi-language?`,
      answer: (
        <>
          Incorporating multiple languages is possible, but we would need more info based on your event’s specific needs
          and programming scope. Best way to learn more about this is to contact us{' '}
          <Link to='/contact' className='email-link'>
            contact us.
          </Link>
        </>
      )
    },
    {
      question: `Can I use this platform for international events?`,
      answer: `Yes you can.  We will need to review the location(s) with you and discuss details and various considerations surrounding data capture, security, and time zones, during the planning process.`
    }
  ]

  const TechnicalQuestions: IFaq[] = [
    {
      question: `What browsers support the platform?`,
      answer: (
        <>
          Vx360 is optimized for desktop and mobile. While the application performs well across most browsers, it has
          been built to perform best through Google Chrome - so where possible it is best to use this for the ideal
          experience. <br />
          <br /> Other supported browsers include the latest versions of Firefox, Edge, Safari, and mobile versions of
          Safari and Chrome.
        </>
      )
    },
    {
      question: `Do you offer technical support for events?`,
      answer: `We do! A technical support plan is included in each package for the duration of your event or experience.`
    },
    {
      question: `Can I restrict/password protect access to my virtual event?`,
      answer: `Yes, and we do require it depending on the usage of certain features.`
    },
    {
      question: `Can I use a custom domain to host my virtual event/experience?`,
      answer: `Yes. Our development and production teams are happy to review with you the process for setting up a custom domain. However, this is only available as an add-on to premium packages. `
    },
    {
      question: `I might have guests with accessibility needs. Can you accommodate?`,
      answer: `We are following best practices for accessibility, however there are a lot of different forms of this. We’d love to discuss this more with you directly and specifically, so we can best provide or make recommendations.`
    },
    {
      question: `What do you recommend for optimal viewing and load time, for the general attendee?`,
      answer: `Vx360 doesn’t require any separate plugins or downloads. We recommend users close out of any other programs they might be running on their device, and close additional browser windows or tabs for optimal performance.`
    },
    {
      question: `Does the platform have the ability to capture or track data and if so what is that?`,
      answer: (
        <>
          Vx360 has its own proprietary data-tracking API. We’ll work with your event stakeholders to set up a real-time
          dashboard that can be viewed throughout and post-event.{' '}
          <Link to='/explore' className='email-link'>
            View more of our all-inclusive features here.
          </Link>
        </>
      )
    },
    {
      question: `I have a question not listed or covered. Who can I contact for inquiries regarding Vx360 or MVRK?`,
      answer: (
        <>
          Please reach out to{' '}
          <a href='mailto:hello@mvrk.co' className='email-link'>
            hello@mvrk.co
          </a>{' '}
          with your question. We’ll be happy to help. Or might we suggest{' '}
          <a className='email-link' onClick={() => setDrawerContent(DrawerOptions[DrawerTypes.demoDrawer])}>
            scheduling a demo?
          </a>
        </>
      )
    }
  ]

  return (
    <x.div py={{ xs: '2rem', md: '2px' }} className='max-content-width' margin='0 auto'>
      <Faqs faqs={GeneralSalesQuestions} heading={{ number: '01', title: 'General / Sales Questions' }} />
      <Faqs faqs={EventFocusedQuestions} heading={{ number: '02', title: 'Event-focused Questions' }} />
      <Faqs faqs={TechnicalQuestions} heading={{ number: '03', title: 'Technical Questions' }} />
    </x.div>
  )
}
