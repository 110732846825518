import { IPricing } from 'types'

export const PricingFeatures: IPricing[] = [
  {
    feature: 'Registration System',
    standard: true,
    premium: true
  },
  {
    feature: 'Choice of 3D Environment',
    standard: true,
    premium: true
  },
  {
    feature: '360° Navigational System',
    standard: true,
    premium: true
  },
  {
    feature: 'FAQ Page/Guided Platform Tutorial',
    standard: true,
    premium: true
  },
  {
    feature: 'In-Platform Notifications',
    standard: true,
    premium: true
  },
  {
    feature: 'VOD Integration',
    standard: true,
    premium: true
  },
  {
    feature: 'Live Stream Integration',
    standard: true,
    premium: true
  },
  {
    feature: 'Polling',
    standard: true,
    premium: true
  },
  {
    feature: 'Reporting/Dashboard',
    standard: true,
    premium: true
  },
  {
    feature: 'Public Chat (Channels)',
    standard: true,
    premium: true
  },
  {
    feature: 'Moderation Controls',
    standard: true,
    premium: true
  },
  {
    feature: '1:1 Text & Video Chat',
    standard: false,
    premium: true
  },
  {
    feature: 'User Directory & Profiles',
    standard: false,
    premium: true
  },
  {
    feature: 'Group Video Sessions',
    standard: false,
    premium: true
  },
  {
    feature: 'Presentation Controls',
    standard: false,
    premium: true
  },
  {
    feature: 'Sessions Recording',
    standard: false,
    premium: true
  }
]
