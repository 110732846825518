const aspectRatio = {
  '1:1': 1,
  '16:9': 9 / 16,
  '4:3': 3 / 4,
  '3:2': 2 / 3,
  '8:5': 5 / 8
}

export const getAspectRatio = (ratio: string): number => {
  return aspectRatio[ratio]
}
