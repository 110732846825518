import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { IGalleryImage, x } from 'helpers'
import { IModalMedia, ITemplateSectionInfo } from 'types'
import { Text, Video } from 'components'

export const LaunchpadImages: IGalleryImage[] = [
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad1.png'
        alt='Launchpad 1'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Launchpad',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad1.png'
          alt='Launchpad 1'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad2.png'
        alt='Launchpad 2'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Launchpad',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad2.png'
          alt='Launchpad 2'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad3.png'
        alt='Launchpad 3'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Launchpad',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad3.png'
          alt='Launchpad 3'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad4.png'
        alt='Launchpad 4'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Launchpad',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad4.png'
          alt='Launchpad 4'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad5.png'
        alt='Launchpad 5'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Launchpad',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad5.png'
          alt='Launchpad 5'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad6.png'
        alt='Launchpad 6'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Launchpad',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad6.png'
          alt='Launchpad 6'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad7.png'
        alt='Launchpad 7'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Launchpad',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad7.png'
          alt='Launchpad 7'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  }
]

export const LaunchpadVideo: IModalMedia = {
  title: 'Launchpad',
  video: (
    <Video
      url='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpadWalkthrough.mp4'
      light='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpad1.png'
    />
  )
}

export const LaunchpadInfo: ITemplateSectionInfo = {
  title: 'Launchpad',
  titleNumber: '03',
  description: (
    <Text color='mvrkLightGray' fontSize='16px'>
      Inspired by exhibit halls and blank-space venues, <strong>Launchpad</strong> is an intimate and focused
      environment. The ideal showcase for brand moments and experiences whose priorities are highlighting value,
      demonstrating differentiation, and revealing content. With 7-10 content areas throughout the space, this virtual
      environment is where you, your messaging, and your product or service are the main event. <br />
      <br />
      <x.span color='white' fontWeight='bold'>
        Suggested uses:
      </x.span>{' '}
      Meetings, product announcements, press events and exhibitions
    </Text>
  ),
  placeholder: (
    <StaticImage
      src='https://d27pgjik7gkkwi.cloudfront.net/templates/launchpadPlaceholder.png'
      alt='Launchpad Placeholder'
      layout='constrained'
      style={{ width: '100%' }}
    />
  ),
  images: LaunchpadImages,
  video: LaunchpadVideo
}
