import { useReducer, useEffect, useState } from 'react'
import { useSwipeable, SwipeableHandlers } from 'react-swipeable'

const previous = (length: number, current: number) => (current - 1 + length) % length

const next = (length: number, current: number) => (current + 1) % length

const transitionTime = 400
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`
const smooth = `transform ${transitionTime}ms ease`

interface StringObject {
  [key: string]: string
}
interface CarouselState {
  offset: number
  desired: number
  active: number
  styles: StringObject
}

const initializeCarouselState = (width): CarouselState => ({
  offset: 0,
  desired: 0,
  active: 0,
  styles: { transform: 'translateX(0)', w: width }
})

enum CarouselActionTypes {
  Next = 'next',
  Prev = 'prev',
  Jump = 'jump',
  Done = 'done',
  Drag = 'drag',
  Style = 'style'
}

interface CarouselAction {
  type: 'next' | 'prev' | 'jump' | 'drag' | 'style'
  payload?: number | { [key: string]: string }
}

function carouselReducer(state: CarouselState, action: CarouselAction): CarouselState {
  switch (action.type) {
    case CarouselActionTypes.Jump:
      return {
        ...state,
        desired: action.payload as number
      }
    case CarouselActionTypes.Next:
      return {
        ...state,
        desired: next(action.payload as number, state.active)
      }
    case CarouselActionTypes.Prev:
      return {
        ...state,
        desired: previous(action.payload as number, state.active)
      }
    case CarouselActionTypes.Drag:
      return {
        ...state,
        offset: action.payload as number
      }
    case CarouselActionTypes.Style:
      return {
        ...state,
        offset: NaN,
        active: state.desired,
        styles: action.payload as StringObject
      }
    default:
      return state
  }
}

export function useCarousel(
  length: number,
  cardWidth: number = 100
): [number, (n: number) => void, SwipeableHandlers, StringObject, boolean] {
  const [state, dispatch] = useReducer(carouselReducer, initializeCarouselState(cardWidth * length))
  const [isSwiping, setSwiping] = useState<boolean>(false)
  const handlers = useSwipeable({
    onSwiping() {
      setSwiping(true)
    },
    onSwiped() {
      setSwiping(false)
    },
    onSwipedLeft() {
      jumpToSlide(state.active + 1)
    },
    onSwipedRight() {
      jumpToSlide(state.active - 1)
    },
    trackMouse: true,
    trackTouch: true
  })

  const jumpToSlide = n => {
    if (n > length) {
      n = 0
    } else if (n < 0) {
      n = length
    }
    dispatch({ type: CarouselActionTypes.Jump, payload: n })
  }

  useEffect(() => {
    if (state.desired !== state.active) {
      const style = {
        transition: smooth,
        transform: `translateX(-${state.desired * cardWidth}px)`
      }
      dispatch({ type: CarouselActionTypes.Style, payload: style })
    } else if (!isNaN(state.offset)) {
      let style = {}
      if (state.offset !== 0) {
        style = { transform: `translateX(${state.offset}px)` }
      } else {
        style = { transition: elastic, transform: `translateX(-${state.active * cardWidth}px)` }
      }
      dispatch({ type: CarouselActionTypes.Style, payload: style })
    }
  }, [state.desired])

  return [state.active, jumpToSlide, handlers, state.styles, isSwiping]
}
