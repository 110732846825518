import React, { FC } from 'react'
import styled, { breakpoints, css } from '@xstyled/styled-components'
import { useLocation } from '@reach/router'

import { Flex, Button, ColorBarsHorizontal } from 'components'
import { Link } from '../controls'
import { x } from 'helpers'

interface MobileMenuProps {
  menuOpen: boolean
  setMenuOpen: (open: boolean) => void
}
const MenuWrapper = styled.divBox`
  position: fixed;
  height: calc(100vh - 57px);
  top: 57px;
  background-color: #111;
  z-index: 999;
  right: 0;
  width: ${props => (props.open ? '100vw' : '0px')};
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  a {
    transform: translate3d(500px, 0, 0);
    transition: transform 0.1s ease-in;
  }
  &.active {
    a {
      transform: translate3d(0, 0, 0);
      transition: transform 500ms ease-out;
      &:first-child {
        transition-delay: 0.05s;
      }
      &:nth-child(2) {
        transition-delay: 0.1s;
      }
      &:nth-child(3) {
        transition-delay: 0.15s;
      }
      &:last-child {
        transition-delay: 0.2s;
      }
    }
  }
  ${breakpoints({
    md: css`
      display: none;
    `
  })}
`
//@ts-ignore
const MobileNavLink = styled(Link)`
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem 0;
`

export const MobileMenu: FC<MobileMenuProps> = ({ menuOpen, setMenuOpen }) => {
  const location = useLocation()
  const isSelected = (route: string) => location.pathname.includes(route)

  return (
    <MenuWrapper
      open={menuOpen}
      className={menuOpen ? 'active' : ''}
      top={{ xs: '57px', sm: '100px' }}
      h={{ xs: 'calc(100vh - 57px)', sm: 'calc(100vh - 100px)' }}
    >
      <Flex flexDirection='column' justifyContent='space-between' h='100%' position='relative'>
        <Flex flexDirection='column' justifyContent='space-between' alignItems='center' padding='2.5rem' h='50%'>
          {['explore', 'pricing', 'faq', 'contact'].map(link => (
            <MobileNavLink key={link} to={`/${link}`} selected={isSelected(link)}>
              {link}
            </MobileNavLink>
          ))}
        </Flex>
        <Flex h='50%' flexDirection='column' alignItems='center' justifyContent='center'>
          <Button variant='transparent' color='white' border='1px solid white' onClick={() => setMenuOpen(false)}>
            Request Demo
          </Button>
        </Flex>
      </Flex>
      <x.div position='absolute' bottom='0' left='50%' transform='translateX(-50%)'>
        <ColorBarsHorizontal />
      </x.div>
    </MenuWrapper>
  )
}
