import React, { useReducer, createContext, Dispatch, useContext } from 'react'

import { IDrawerContent } from 'types'
import { DrawerOptions } from 'helpers'

interface IAppContextObject {
  drawerContent?: IDrawerContent | null
}

const initialState = {
  drawerContent: null
}

enum Actions {
  SET_DRAWER_CONTENT
}

export const AppContext = createContext<{
  appState: IAppContextObject
  dispatch: Dispatch<any>
  setDrawerContent: (payload: IDrawerContent | null) => void
}>({
  appState: initialState,
  dispatch: () => null,
  setDrawerContent: () => null
})

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.SET_DRAWER_CONTENT:
      return {
        ...state,
        drawerContent: action.payload
      }
    default:
      return state
  }
}

export const AppStateProvider = props => {
  const [appState, dispatch] = useReducer(reducer, initialState)

  const setDrawerContent = (payload: string | IDrawerContent | null) =>
    dispatch({
      type: Actions.SET_DRAWER_CONTENT,
      payload: typeof payload === 'string' ? DrawerOptions[payload] : payload
    })

  return <AppContext.Provider value={{ appState, dispatch, setDrawerContent }}>{props.children}</AppContext.Provider>
}

export const useAppState = () => useContext(AppContext)
