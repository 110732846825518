import { defaultTheme, style, createCss, compose, system } from '@xstyled/styled-components'

export const theme = {
  ...defaultTheme,
  useColorSchemeMediaQuery: false,
  colors: {
    ...defaultTheme.colors,
    primary: '#38F4F1',
    error: '#F1122D',
    labelGray: '#EEE',
    mvrkGray: '#687474',
    mvrkLightGray: '#818D8D',
    mvrkDarkGray: '#191A1A',
    mvrkDarkerGray: '#111'
  },
  fonts: {
    ...defaultTheme.fonts,
    proxima: 'proxima-nova, Metropolis',
    nimbus: 'nimbus-sans, Polt Black',
    anonymous: 'anonymous-pro, Anonymous Pro'
  }
}

const borderImage = style({ prop: 'borderImage' })

const textStroke = style({
  prop: 'ts',
  css: '-webkit-text-stroke'
})

const textFillColor = style({
  prop: 'tfc',
  css: '-webkit-text-fill-color'
})

export const { css, styled, x } = createCss(compose(system, borderImage, textStroke, textFillColor))
