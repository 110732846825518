import React, { FC } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const MacDesktops: FC<any> = ({ containerStyles = {} }) => {
  return (
    <StaticImage
      src='https://d27pgjik7gkkwi.cloudfront.net/imac.png'
      alt='Mac Desktops'
      style={containerStyles}
    />
  )
}
