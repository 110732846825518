import React from 'react'
import { Splash } from 'components'

export const TemplatesSplash = () => {
  return (
    <Splash
      alignment='left-padded'
      desktopImg='https://d27pgjik7gkkwi.cloudfront.net/templatesSplash.png'
      mobileImg='https://d27pgjik7gkkwi.cloudfront.net/templatesSplashMobile.png'
      title1='Ready to go'
      title2='Templates'
      description='A blank canvas isn’t the ideal process for all digital experiences. Allowing for timeline efficiency and streamlined approvals, select from our four templated, signature environments that give you a head start on planning an experience that is both state-of-the-art and highly engaging. Each accounts for multiple branding opportunities, customizable colors, textures and high dynamic range imagery.'
    />
  )
}
