import React, { FC } from 'react'
import { LinkButton } from 'components'
import { x, styled } from 'helpers'
import { IPricing } from 'types'

interface MobilePricingProps {
  heading: string
  description: string
  features: IPricing[]
  to: string
  footer?: boolean
}

const Heading = styled.h2Box`
  font-family: nimbus;
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
`

const Description = styled.pBox`
  font-family: proxima;
  color: #818d8d;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 1rem 0;
`
const HeaderWrapper = styled.divBox`
  width: 100%;
  padding: 1rem;
  background-color: #191a1a;
  border-image: linear-gradient(180deg, #687474 0%, rgba(104, 116, 116, 0) 100%) 1;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid #818d8d;
  border-bottom: none;
`
const FeaturesWrapper = styled.divBox`
  display: flex;
  flex-direction: column;
  background-color: #191919;
  border-image: linear-gradient(180deg, #687474 0%, rgba(104, 116, 116, 0) 50%, #687474 100%) 1;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid #818d8d;
  border-bottom: 1px solid #818d8d;
  padding: 1rem 0;
`

const FooterWrapper = styled.divBox`
  border-top: 1px solid;
  border-image: linear-gradient(90deg, rgba(104, 116, 116, 0) 0%, #687474 50%, rgba(104, 116, 116, 0) 100%) 1;
  padding: 1rem;
  font-size: 0.875rem;
  color: #818d8d;
  font-family: proxima;
`

export const MobilePricing: FC<MobilePricingProps> = ({ heading, description, features, to, footer }) => {
  return (
    <x.div w='100%' py='1rem'>
      <HeaderWrapper>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
        <LinkButton to={to} variant='transparent'>
          Contact Us
        </LinkButton>
      </HeaderWrapper>
      <FeaturesWrapper>
        <x.ul listStyleType='none'>
          {features.map(feature => (
            <x.li textTransform='uppercase' p='1rem'>
              {feature.feature}
            </x.li>
          ))}
        </x.ul>

        {footer && (
          <FooterWrapper>
            <x.p fontWeight='700' my='.5rem'>
              *Lorem ipsum dolor sit amet, consectetur adipiscing
            </x.p>

            <x.p fontWeight='400' my='.5rem'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit sed imperdiet varius ac. Pulvinar et vitae
              arcu, morbi. Nisl amet mauris sed amet in. Sit ac est sem id enim.
            </x.p>
          </FooterWrapper>
        )}
      </FeaturesWrapper>
    </x.div>
  )
}
