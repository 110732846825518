import React, { FC, useState } from 'react'

import { MediaModal, TemplateImageCarousel } from 'components'
import {
  TemplatesContainer,
  TemplatesContentContainer,
  TemplatesImagesContainer,
  TemplatesMediaContainer,
  TemplatesTextContainer
} from './TemplatesLayout'

import { FoundryImages, x } from 'helpers'
import { ITemplateSectionInfo } from 'types'
import { useMobileDetect } from 'hooks'

interface TemplateSectionProps {
  info: ITemplateSectionInfo
  reverse?: boolean
  lastSection?: boolean
}

export const TemplateSection: FC<TemplateSectionProps> = ({ info, reverse, lastSection }) => {
  const { isMobile } = useMobileDetect()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [video, setVideo] = useState<boolean>(false)

  const openModal = (index: number) => {
    setVideo(false)
    setSelectedIndex(index)
    setModalOpen(true)
  }

  const openVideoModal = () => {
    setVideo(true)
    setSelectedIndex(-1)
    setModalOpen(true)
  }

  const closeModal = (val: boolean) => {
    setVideo(val)
    setModalOpen(val)
  }

  return (
    <x.section
      {...(!reverse ? { bg: 'mvrkDarkGray' } : {})}
      {...(lastSection
        ? {
            background:
              'url(https://d27pgjik7gkkwi.cloudfront.net/backgroundDots.png) center bottom/200px 200px repeat-x'
          }
        : {})}
    >
      <TemplatesContainer>
        <TemplatesContentContainer>
          <TemplatesMediaContainer reverse={reverse}>
            <x.div onClick={openVideoModal} cursor={{ hover: 'pointer' }}>
              {isMobile && info.placeholderMobile ? info.placeholderMobile : info.placeholder}
            </x.div>
          </TemplatesMediaContainer>
          <TemplatesTextContainer
            title={info.title}
            titleNumber={info.titleNumber}
            reverse={reverse}
            onPlayTour={openVideoModal}
          >
            {info.description}
          </TemplatesTextContainer>
        </TemplatesContentContainer>
        <TemplatesImagesContainer>
          {isMobile ? (
            <TemplateImageCarousel images={info.images} title={info.title} onClick={openModal} />
          ) : (
            info.images.map((image, index) => (
              <x.span
                key={`${info.title}-image-${index}`}
                cursor={{ hover: 'pointer' }}
                onClick={() => openModal(index)}
              >
                {image.thumbSrc}
              </x.span>
            ))
          )}
        </TemplatesImagesContainer>
      </TemplatesContainer>
      <MediaModal
        isOpen={modalOpen}
        setIsOpen={closeModal}
        media={video && selectedIndex < 0 ? info.video : info.images[selectedIndex < 0 ? 0 : selectedIndex]?.modal}
        mediaAspectRatio={video && selectedIndex < 0 ? '16:9' : undefined}
        onPreviousClick={() => setSelectedIndex(selectedIndex - 1)}
        onNextClick={() => setSelectedIndex(selectedIndex + 1)}
        previousDisabled={selectedIndex === -1}
        nextDisabled={selectedIndex + 1 === info.images.length}
      />
    </x.section>
  )
}
