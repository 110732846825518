import React, { FC, useState, useEffect, memo } from 'react'
import styled, { SystemProps, css, breakpoints } from '@xstyled/styled-components'
import { useLocation } from '@reach/router'
// components
import { Button, Flex, MobileMenuOpen, MobileMenuClose, MobileMenu } from 'components'
import { Link } from '../controls'
import { MVRKLogo } from 'icons'
import { useScrollTop } from 'hooks'
import { DrawerTypes, x, DrawerOptions } from 'helpers'
import { useAppState } from 'providers'

// @ts-ignore
const NavLink = styled(Link)`
  ${breakpoints({
    xs: css`
      text-align: center;
      padding: 2.5rem;
      flex: 1 0 25%;
      padding: 2.5rem 0.5rem;
      font-size: 0.75rem;
      border-top: ${props => (props.selected ? '2px #38f4f1 solid' : 'none')};
      &:hover {
        border-top: 2px #38f4f1 solid;
      }
    `,
    lg: css`
      padding: 2.5rem;
    `
  })}
`

export const NavBar = memo(() => {
  const location = useLocation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(true)
  const isTopOfPage = useScrollTop()
  const isSelected = (route: string) => location.pathname.includes(route)
  const { setDrawerContent } = useAppState()

  const handleResize = () => {
    mobileMenuOpen && setMobileMenuOpen(false)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Putting this here to close the mobile menu on route change - Gatsby's Link doesn't seem to support onClick
  useEffect(() => {
    mobileMenuOpen && setMobileMenuOpen(false)
  }, [location])

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add('menu-open')
    } else {
      document.body.classList.remove('menu-open')
    }
  }, [mobileMenuOpen])

  return (
    <>
      <NavBarContainer
        h={{ xs: '50px', md: '100px' }}
        w='100vw'
        maxW='100vw'
        justifyContent='space-between'
        position='fixed'
        top='0'
        backgroundColor={isTopOfPage ? 'transparent' : 'rgba(17,17,17, .9)'}
        transition='background-color ease-in-out .2s'
        zIndex='2'
      >
        <Flex>
          <Link to='/' color={{ hover: '#ffffff' }} display='flex' alignItems='center' justifyContent='center'>
            <x.img
              w={{ xs: '40px', sm: '75px' }}
              mt={{ xs: '-3px', sm: '-6px' }}
              src='https://d27pgjik7gkkwi.cloudfront.net/vx360Logo.png'
            />
            <x.span fontSize={{ xs: '6px', sm: '10px' }} mx='.25rem'>
              powered by
            </x.span>{' '}
            <MVRKLogo width={28} height={19} />
          </Link>
        </Flex>

        <Flex alignItems='center' justifyContent='flex-end' display={{ xs: 'none', md: 'flex' }}>
          <Flex alignItems='center' mr='2.5rem'>
            <NavLink to='/explore' selected={isSelected('explore')}>
              Explore
            </NavLink>
            <NavLink to='/pricing' selected={isSelected('pricing')}>
              Pricing
            </NavLink>
            <NavLink to='/faq' selected={isSelected('faq')}>
              FAQ
            </NavLink>
            <NavLink to='/contact' selected={isSelected('contact')}>
              Contact
            </NavLink>
          </Flex>

          <Button variant='transparent' onClick={() => setDrawerContent(DrawerOptions[DrawerTypes.demoDrawer])}>
            Request Demo
          </Button>
        </Flex>
        <Flex display={{ xs: 'flex', md: 'none' }} alignItems='center' justifyContent='center'>
          <x.button
            backgroundColor='transparent'
            userSelect={{ _: 'none', focus: 'none' }}
            outline={{ _: 'none', focus: 'none' }}
            border='none'
            cursor={{ hover: 'pointer' }}
            onClick={() => setMobileMenuOpen(mobileMenuOpen => !mobileMenuOpen)}
          >
            {mobileMenuOpen ? <MobileMenuClose /> : <MobileMenuOpen />}
          </x.button>
        </Flex>
      </NavBarContainer>
      <MobileMenu menuOpen={mobileMenuOpen} setMenuOpen={setMobileMenuOpen} />
    </>
  )
})

export const NavBarContainer: FC<SystemProps> = props => (
  <x.nav
    display='flex'
    borderBottom='1px solid'
    borderImage='linear-gradient(90deg, rgba(104, 116, 116, 0) 2.08%, #687474 19.27%, #687474 80.73%, rgba(104, 116, 116, 0) 97.92%) 1'
    px='40px'
    py='28px'
    {...props}
  />
)
