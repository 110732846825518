import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { IGalleryImage, x } from 'helpers'
import { IModalMedia, ITemplateSectionInfo } from 'types'
import { Text, Video } from 'components'

export const CollectiveImages: IGalleryImage[] = [
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective1.png'
        alt='Collective 1'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Collective',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective1.png'
          alt='Collective 1'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective2.png'
        alt='Collective 2'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Collective',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective2.png'
          alt='Collective 2'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective3.png'
        alt='Collective 3'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Collective',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective3.png'
          alt='Collective 3'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective4.png'
        alt='Collective 4'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Collective',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective4.png'
          alt='Collective 4'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective5.png'
        alt='Collective 5'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Collective',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective5.png'
          alt='Collective 5'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective6.png'
        alt='Collective 6'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Collective',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective6.png'
          alt='Collective 6'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  },
  {
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective7.png'
        alt='Collective 7'
        width={142}
        height={142}
        style={{ marginRight: '2px' }}
      />
    ),
    modal: {
      title: 'Collective',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/templates/collective7.png'
          alt='Collective 7'
          layout='fullWidth'
          quality={100}
          style={{ maxWidth: '900px', maxHeight: '506px' }}
        />
      )
    }
  }
]

export const CollectiveVideo: IModalMedia = {
  title: 'Collective',
  video: (
    <Video
      url='https://d27pgjik7gkkwi.cloudfront.net/templates/collectiveWalkthrough.mp4'
      light='https://d27pgjik7gkkwi.cloudfront.net/templates/collective1.png'
    />
  )
}

export const CollectiveInfo: ITemplateSectionInfo = {
  title: 'Collective',
  titleNumber: '04',
  description: (
    <Text color='mvrkLightGray' fontSize='16px'>
      Inspired by incubation labs and learning centers, <strong>Collective</strong> is a visual, curated and educational
      environment. This layout highlights your product or service offerings key points, and can further illustrate
      related details and processes. With 8-10 content areas throughout the space, this virtual environment brings
      visitors into your command center, thereby making them feel a part of the brand from the start.
      <br />
      <br />
      <x.span color='white' fontWeight='bold'>
        Suggested uses:
      </x.span>{' '}
      Product releases, showcases announcements; conferences and generalized corporate events
    </Text>
  ),
  placeholder: (
    <StaticImage
      src='https://d27pgjik7gkkwi.cloudfront.net/templates/collectivePlaceholder.png'
      alt='Collective Placeholder'
      layout='constrained'
      style={{ width: '100%' }}
    />
  ),
  images: CollectiveImages,
  video: CollectiveVideo
}
