import React, { FC } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import styled, { css, SystemProps } from '@xstyled/styled-components'
import { breakpoints, system } from '@xstyled/system'

interface LinkProps extends SystemProps {
  to: string
}
interface LinkButtonProps extends SystemProps {
  variant?: 'primary' | 'transparent'
  to: string
}

const SharedLinkButtonStyles = css`
  text-decoration: none;
  padding: 2 4;
  font-family: proxima;
  text-transform: uppercase;
  border-radius: 46;
  text-align: center;
  &:focus {
    outline: none;
  }
  ${p => css`
    background-color: ${p.variant};
    color: ${p.color || p.variant === 'transparent' ? 'white' : 'black'};
    border: ${p.border || p.variant === 'transparent' ? '1px solid white' : ''};
    &:hover {
      box-shadow: ${p.variant === 'primary' ? '0px 5px 30px rgba(56, 244, 241, 0.35)' : 'none'};
      border-color: ${p.border || p.variant === 'transparent' ? 'primary' : ''};
      color: ${p.border || p.variant === 'transparent' ? 'primary' : ''};
    }
  `}
  ${breakpoints({
    xs: css`
      width: 130px;
      height: 40px;
      font-size: 0.75rem;
      line-height: 25px;
    `,
    md: css`
      width: 168px;
      height: 45px;
      line-height: 30px;
    `,
    lg: css`
      font-size: 0.875rem;
    `
  })}
`
// Extend an existing component
// @ts-ignore
const StyledLink = styled(GatsbyLink)`
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.875rem;
  ${p => css`
    color: ${p.selected ? 'primary' : 'white'};
    &:hover {
      color: primary;
    }
  `};
  ${system}
`
// @ts-ignore
const StyledLinkButton = styled(GatsbyLink)`
  ${SharedLinkButtonStyles}
  ${system}
`
// @ts-ignore
const StyledAnchorLinkButton = styled(AnchorLink)`
  ${SharedLinkButtonStyles}
  ${system}
`

export const Link: FC<LinkProps> = ({ children, ...props }) => <StyledLink {...props}>{children}</StyledLink>

export const LinkButton: FC<LinkButtonProps> = ({ children, variant = 'primary', ...props }) => {
  const Wrapper = /#/.test(props.to) ? StyledAnchorLinkButton : StyledLinkButton
  return (
    <Wrapper variant={variant} {...props}>
      {children}
    </Wrapper>
  )
}
