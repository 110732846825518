import React, { FC } from 'react'
import { SystemProps } from '@xstyled/styled-components'

import { x } from 'helpers'

interface BackdropProps extends SystemProps {
  hideBackdrop?: boolean
  open: boolean
  animationTime?: string
}

export const Backdrop: FC<BackdropProps> = ({ hideBackdrop, open, animationTime, ...props }) => (
  <x.div
    {...props}
    position='fixed'
    w={{ _: '100%', md: '100vw' }}
    h='100vh'
    zIndex={open ? 9990 : -1}
    top={0}
    right={0}
    left={0}
    bottom={0}
    backgroundColor={hideBackdrop ? 'transparent' : 'rgba(0,0,0,0.5)'}
    transition={`background-color ${animationTime}s ease-in-out, z-index ${open ? 0.1 : 0.5}s ease-in-out`}
  />
)
