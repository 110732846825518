import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { CustomImage } from './'

export const ColorBars: FC<any> = ({ containerStyles = {}, ...props }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "colorBars.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 15, height: 186, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <CustomImage
      image={data.file.childImageSharp.gatsbyImageData}
      alt='Color Bars'
      {...props}
      style={containerStyles}
    />
  )
}
