import React, { FC, useState } from 'react'
import styled, { breakpoints, css, SystemProps } from '@xstyled/styled-components'

import { NumberedHeading, MediaModal } from 'components'
import { IGalleryImage, x } from 'helpers'

interface GalleryProps extends SystemProps {
  columns: number
  rows: number
  images: IGalleryImage[]
  heading?: {
    title: string
    number: string
    description?: string
  }
  showGradient?: boolean
}

const Grid = styled.divBox`
  ${breakpoints({
    xs: css`
      grid-template-columns: repeat(2, 1fr);
    `,
    md: css`
      grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
    `
  })};
  display: grid;
  height: auto;
  grid-auto-flow: row;
  grid-template-rows: ${props => `repeat(${props.rows}, 1fr)`};
  grid-auto-rows: minmax(20px, auto);
  gap: 0;
  column-gap: 0;
  img {
    opacity: 1;
  }
  &:hover {
    & img {
      opacity: 0.5 !important;
      transform: scale(1);
    }
  }
`

const Cell = styled.divBox`
  background-color: #000;
  grid-row-end: ${props => `span ${props.height}`};
  grid-column-end: ${props => `span ${props.width}`};
  height: 100%;
  min-width: 0;
  & img {
    transition: all 0.25s ease-in-out !important;
    &:hover {
      opacity: 1 !important;
      transform: scale(1.3);
    }
  }

  &:hover {
    cursor: pointer;
  }
`

export const Gallery: FC<GalleryProps> = ({ columns, rows, images, heading, showGradient, ...props }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const bgImage = 'https://d27pgjik7gkkwi.cloudfront.net/backgroundDots.png'

  const openModal = (index: number) => {
    setSelectedIndex(index)
    setModalOpen(true)
  }

  const handleToggleModal = (isOpen: boolean) => {
    setModalOpen(isOpen)
    if (!isOpen) {
      setSelectedIndex(0)
    }
  }

  return (
    <>
      <x.div
        w='100%'
        h='100%'
        backgroundSize='cover'
        background={{
          sm: showGradient ? 'linear-gradient(to bottom, transparent 0 calc(100% - 200px), #191A1A 50% 100%)' : ''
        }}
        {...props}
      >
        <x.div
          w='100%'
          background={{
            sm: !showGradient
              ? `url(${bgImage}) center bottom/450px 450px repeat-x`
              : `url(${bgImage}) center center/300px 300px repeat-x`
          }}
          {...props}
        >
          <x.div className='max-content-width' margin='0 auto' pb='6rem' pt='3rem'>
            {/* title only shows on the home page */}
            {heading && (
              <NumberedHeading number={heading.number} title={heading.title}>
                {heading.description || null}
              </NumberedHeading>
            )}
            <Grid columns={columns} rows={rows} mt={{ _: '35px' }}>
              {images.map((image, index) => (
                <Cell key={`cell-${index}`} height={image.height} width={image.width} onClick={() => openModal(index)}>
                  {image.thumbSrc}
                </Cell>
              ))}
            </Grid>
          </x.div>
        </x.div>
      </x.div>
      <MediaModal
        isOpen={modalOpen}
        setIsOpen={handleToggleModal}
        media={images[selectedIndex].modal}
        mediaAspectRatio='16:9'
        onPreviousClick={() => setSelectedIndex(selectedIndex - 1)}
        onNextClick={() => setSelectedIndex(selectedIndex + 1)}
        previousDisabled={selectedIndex === 0}
        nextDisabled={selectedIndex + 1 === images.length}
      />
    </>
  )
}
