export * from './ColorBars'
export * from './ColorBarsHorizontal'
export * from './DrawerHero'
export * from './FlatTemplate'
export * from './HighlightImages'
export * from './MacDesktops'
export * from './MacDesktopsMobile'

import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { system } from '@xstyled/styled-components'

// @ts-ignore
export const CustomImage = styled(GatsbyImage)`
  ${system}
`
