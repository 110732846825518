import React from 'react'
import { Splash } from 'components'

export const HomeSplash = () => {
  return (
    <Splash
      alignment='left'
      desktopImg='https://d27pgjik7gkkwi.cloudfront.net/homeSplash.png'
      mobileImg='https://d27pgjik7gkkwi.cloudfront.net/homeSplashMobile.png'
      title1='Virtual'
      title2='Innovation'
      description='Vx360 is a virtual platform built for the now and the future. It’s an unparalleled, 360-degree immersive experience with an all-inclusive library of features built to support conference hosting and digital
          experiential events.'/>
  )
}
