import React, { FC, useEffect, useState } from 'react'
import toast, { ToastOptions } from 'react-hot-toast'
import * as Sentry from '@sentry/gatsby'

import { Button } from '../controls'
import { Flex } from '../layout'
import { Input } from './Input'
import { Select } from './Select'

import { emailRule, phoneRule, requiredRule, x } from 'helpers'
import { useForm } from 'hooks'
import { TextArea } from './TextArea'

export const formObject = {
  firstName: {
    validationRules: [requiredRule()]
  },
  lastName: {
    validationRules: [requiredRule()]
  },
  email: {
    validationRules: [requiredRule(), emailRule()]
  },
  phoneNumber: {
    validationRules: [requiredRule(), phoneRule()]
  },
  company: {
    validationRules: [requiredRule()]
  },
  interestedIn: {
    validationRules: [requiredRule()]
  }
}

// When adding new options make the values human readable since the value is what's passed to the email
const contactOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Vx360 templates', label: 'Vx360 templates' },
  { value: 'Vx360 custom', label: 'Vx360 custom' },
  { value: 'Learning more', label: 'Learning more' },
  { value: 'Technical questions', label: 'Technical questions' },
  { value: 'Other', label: 'Other' }
]

const requestDemoOptions = [
  { value: '', label: 'Select an option' },
  { value: 'Vx360 templates', label: 'Vx360 templates' },
  { value: 'Vx360 custom build', label: 'Vx360 custom build' },
  { value: 'General learning', label: 'General learning' },
  { value: 'Other', label: 'Other' }
]
interface RequestDemoFormProps {
  isContactForm?: boolean
  labelColor?: string
  buttonVariant?: 'primary' | 'transparent'
}

export const RequestDemoForm: FC<RequestDemoFormProps> = ({ isContactForm, labelColor, buttonVariant }) => {
  const selectOptions = isContactForm ? contactOptions : requestDemoOptions

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    phoneNumber: '',
    interestedIn: selectOptions[0].value
  }

  // Add the comments input to the formObject if isContactForm === true, otherwise the form validation will fail
  if (isContactForm) {
    formObject['comments'] = {
      validationRules: []
    }
    initialValues['comments'] = ''
  } else if (!isContactForm && formObject?.['comments']) {
    delete formObject['comments']
    delete initialValues['comments']
  }

  const toastOptions: ToastOptions = {
    duration: 5000,
    position: 'top-center',
    style: {
      borderRadius: 0,
      background: '#fff',
      fontFamily: 'proxmia-nova, Metropolis'
    }
  }

  const [emailSending, setEmailSending] = useState<boolean>(false)

  const onSubmit = async () => {
    setEmailSending(true)
    const toastId = toast.loading('Submitting request...', {
      ...toastOptions
    })

    try {
      await fetch('https://c2niigjwkl.execute-api.us-east-1.amazonaws.com/prod/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        mode: 'no-cors',
        body: JSON.stringify({ ...values, requestType: isContactForm ? 'Contact Request' : 'Demo Request' })
      })

      toast.success('Thank you! Your request was submitted.', {
        ...toastOptions,
        id: toastId
      })

      resetForm()
    } catch (error) {
      Sentry.captureException(error)
      toast.error('There was an error, please try again.', {
        ...toastOptions,
        id: toastId,
        iconTheme: {
          primary: '#F1122D',
          secondary: '#fff'
        }
      })
    } finally {
      setEmailSending(false)
    }
  }

  const { values, onInputChange, errors, touched, handleSubmit, submitted, resetForm } = useForm(
    initialValues,
    formObject,
    onSubmit
  )

  useEffect(() => {
    return () => {
      resetForm()
    }
  }, [])

  return (
    <form
      onSubmit={e => {
        handleSubmit(e)
      }}
      style={{ width: '100%' }}
    >
      <Flex flexDirection='column'>
        <x.div row>
          <Input
            name='firstName'
            label='First name'
            placeholder='Type your first name'
            type='text'
            value={values.firstName}
            onChange={onInputChange}
            onBlur={onInputChange}
            error={touched.firstName && submitted ? errors.firstName : ''}
            col={{ xs: 1, md: 1 / 2 }}
            containerStyles={{ mb: '20px', pr: { _: 0, md: '10px' } }}
            labelColor={labelColor}
          />
          <Input
            name='lastName'
            label='Last name'
            placeholder='Type your last name'
            type='text'
            value={values.lastName}
            onChange={onInputChange}
            onBlur={onInputChange}
            error={touched.lastName && submitted ? errors.lastName : ''}
            col={{ xs: 1, md: 1 / 2 }}
            containerStyles={{ mb: '20px', pl: { _: 0, md: '10px' } }}
            labelColor={labelColor}
          />
        </x.div>
        <x.div row>
          <Input
            name='email'
            placeholder='Type your email'
            type='email'
            value={values.email}
            onChange={onInputChange}
            onBlur={onInputChange}
            error={touched.email && submitted ? errors.email : ''}
            col={{ xs: 1, md: 1 / 2 }}
            containerStyles={{ mb: '20px', pr: { _: 0, md: '10px' } }}
            labelColor={labelColor}
          />
          <Input
            name='company'
            placeholder='Type your company name'
            type='text'
            value={values.company}
            onChange={onInputChange}
            onBlur={onInputChange}
            error={touched.company && submitted ? errors.company : ''}
            col={{ xs: 1, md: 1 / 2 }}
            containerStyles={{ mb: '20px', pl: { _: 0, md: '10px' } }}
            labelColor={labelColor}
          />
        </x.div>
        <x.div row>
          <Input
            name='phoneNumber'
            label='Phone number'
            placeholder='Type your phone number'
            type='tel'
            value={values.phoneNumber}
            onChange={onInputChange}
            onBlur={onInputChange}
            error={touched.phoneNumber && submitted ? errors.phoneNumber : ''}
            col={{ xs: 1, md: 1 / 2 }}
            containerStyles={{ mb: { _: '20px', md: isContactForm ? '20px' : 0 }, pr: { _: 0, md: '10px' } }}
            labelColor={labelColor}
          />
          <Select
            label='Interested in?'
            name='interestedIn'
            options={selectOptions}
            onChange={onInputChange}
            error={touched.interestedIn && submitted ? errors.interestedIn : ''}
            containerStyles={{ pl: { _: 0, md: '10px' }, mb: { _: isContactForm ? '20px' : 0, md: 0 } }}
            labelColor={labelColor}
          />
        </x.div>
        {isContactForm && (
          <x.div row>
            <TextArea
              label='Comment'
              name='comments'
              onChange={onInputChange}
              placeholder='Type your comments here'
              labelColor={labelColor}
              value={values.comments}
            />
          </x.div>
        )}
      </Flex>
      <Button
        type='submit'
        mt='40px'
        variant={buttonVariant || 'transparent'}
        color='black'
        border='1px solid black'
        disabled={emailSending}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </form>
  )
}
