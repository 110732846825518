import React from 'react'
import { breakpoints, css } from '@xstyled/styled-components'
import { Check, LinkButton } from 'components'
import { x, PricingFeatures, styled } from 'helpers'

const TH = styled.thBox`
  text-align: left;
  background-color: #191a1a;
  border-bottom: 1px solid #818d8d;
  vertical-align: top;
  ${breakpoints({
    xs: css`
      padding: 1rem;
    `,
    md: css`
      padding: 2.5rem;
    `
  })}
`

const Heading = styled.h2Box`
  font-family: nimbus;
  font-size: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;
`

const Description = styled.pBox`
  font-family: proxima;
  color: #818d8d;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 1rem 0;
`

const TD = styled.tdBox`
  padding: 0;
  font-family: proxima;
  text-transform: uppercase;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background-color: #191919;
`

export const PricingTable = () => {
  return (
    <x.div display={{ xs: 'none', sm: 'block' }}>
      <x.table w='100%' borderCollapse='collapse' border='1px solid #818D8D'>
        <x.colgroup>
          <x.col w='50%' />
          <x.col w='25%' />
          <x.col w='25%' />
        </x.colgroup>
        <tbody>
          <x.tr>
            <TH borderRight='1px solid #818D8D'>
              <x.div>
                <Heading>Features</Heading>
                <Description>
                  An all-inclusive library built to support conference hosting and digital experiential events. Bringing
                  your event to life, each in their own way aesthetically, functionally, or both.
                </Description>
              </x.div>
            </TH>
            <TH borderRight='1px solid #818D8D'>
              <Heading>Standard</Heading>
              <Description>
                The introductory package that offers the standard features of Vx360, in its most simplistic yet turnkey
                form.
              </Description>
              <LinkButton alignSelf={{ md: 'flex-end' }} mt={{ _: '20px', md: 0 }} to='/contact' variant='transparent'>
                Contact Us
              </LinkButton>
            </TH>
            <TH>
              <Heading>Premium</Heading>
              <Description>
                Inclusive of all standard and premium features but with a few extra perks and options for an even
                further customized experience.
              </Description>
              <LinkButton alignSelf={{ md: 'flex-end' }} mt={{ _: '20px', md: 0 }} to='/contact' variant='transparent'>
                Contact Us
              </LinkButton>
            </TH>
          </x.tr>
          {PricingFeatures.map((feature, i) => (
            <x.tr key={`feature-${i}`} borderBottom='1px solid #818D8D'>
              <TD textAlign='left' borderRight='1px solid #818D8D'>
                {feature.feature}
              </TD>
              <TD textAlign='center' borderRight='1px solid #818D8D'>
                {feature.standard && <Check mx='auto' />}
              </TD>
              <TD textAlign='center'>{feature.premium && <Check mx='auto' />}</TD>
            </x.tr>
          ))}
        </tbody>
      </x.table>
    </x.div>
  )
}
