export interface IFeatureCard {
  title: string
  description: string
  icon: string
}

export const featureCards: IFeatureCard[] = [
  {
    title: 'Fully-Immersive 3D',
    description:
      '360-degree walkable user experience within lifelike virtual venues. Select from state-of-the-art, expertly designed pre-made templated environments or customize your&nbsp;own.',
    icon: '3dIcon.png'
  },
  {
    title: 'Presentation Tools',
    description:
      'Core session tools for highly engaging and premium programming. An open canvas for authentic audio, visual and collaborative&nbsp;conversations.',
    icon: 'PresentationIcon.png'
  },
  {
    title: 'Integrated Registration',
    description:
      'Interact and engage with frictionless and elegant simplicity from RSVP through event end. One secure location for all event management and&nbsp;data. ',
    icon: 'RegistrationIcon.png'
  },
  {
    title: 'Dynamic Programming ',
    description:
      'Powerful, embedded live streaming and video-on-demand offerings and tools that foster connection and&nbsp;community.',
    icon: 'CodeIcon.png'
  },
  {
    title: 'Audience Engagement',
    description:
      'Collaborative networking tools, engagement tactics, user personalization and custom add-ons allow users to actively interact with content and each&nbsp;other.',
    icon: 'AudienceIcon.png'
  },
  {
    title: 'Reporting & Dashboard',
    description:
      'A cloud-based dashboard for event stakeholders. Access to real-time event data and analytics to track and monitor key success&nbsp;metrics.',
    icon: 'ReportingIcon.png'
  }
]
