import React, { FC } from 'react'

import { Flex, Heading, OutlinedText, PlusIcon, Text, LinkButton } from 'components'
import { FlatTemplate } from 'staticImages'

interface PlatformProps {
  isMobile: boolean
}

export const Platform: FC<PlatformProps> = ({ isMobile }) => {
  return (
    <Flex centered column pb={{ _: '0', md: '20px' }} pt={{ _: '40px', md: '100px' }}>
      <OutlinedText fontSize={{ _: '36px', md: '64px', lg: '72px' }} lineHeight={{ _: '32px', md: '60px', lg: '64px' }} textAlign={{ _: 'center' }}>All-In-One</OutlinedText>
      <Heading as='h3' fontSize={{ _: '36px', md: '64px', lg: '72px' }} lineHeight={{_: '32px', md: '60px', lg: '64px' }} textAlign={{ _: 'center' }}>
        3D Virtual {isMobile && <br />}Platform
      </Heading>
      <Flex className='max-content-width'>
        <FlatTemplate />
      </Flex>
      <Flex className='max-content-width' justifyContent='center' column={isMobile}>
        <Flex
          column
          w={{ _: '100%', md: '50%' }}
          minHeight={{ _: 'auto', md: '320px' }}
          m={{ _: '0 auto', md: '0 90px 0 0' }}
          justifyContent='space-between'
          pl={{ sm: '20px' }}
          pr={{ sm: '20px' }}
        >
          <Flex column>
            <Heading
              as='h4'
              fontSize={{ _: '24px', md: '48px' }}
              mb={{ _: '10px', md: '15px' }}
              mt={{ _: '40px', sm: '0' }}
              whiteSpace='nowrap'
              display='flex'
              alignItems='center'
            >
              Templates <PlusIcon ml={{ _: '0.5rem', md: '1rem' }}/>
            </Heading>
            <Text color='mvrkLightGray' fontSize='16px'>
              Efficiency and elegance ready to go. Included in Vx360 is the option to select from 4 ready-to-activate
              templated environments. All offer ample opportunity to be custom branded. Each of the 4 are
              designed and styled to meet various needs, aesthetics, use cases, and event strategy.
            </Text>
          </Flex>
          <LinkButton mt={{ _: '20px', md: 0 }} to='/templates'>
            See Templates
          </LinkButton>
        </Flex>
        <Flex
          column
          w={{ _: '100%', md: '50%' }}
          textAlign={{ md: 'right' }}
          minHeight={{ _: 'auto', md: '320px' }}
          m={{ _: '0 auto', md: 0 }}
          justifyContent='space-between'
          pl={{ sm: '20px' }}
          pr={{ sm: '20px' }}
        >
          <Flex column>
            <Heading
              as='h4'
              fontSize={{ _: '24px', md: '48px' }}
              mb={{ _: '10px', md: '15px' }}
              mt={{ _: '40px', sm: '0' }}
              whiteSpace='nowrap'
              display='flex'
              alignItems='center'
              justifyContent={{ _: 'flex-start', sm: 'flex-end' }}
            >
              {!isMobile && <PlusIcon mr={{ _: '1rem' }} />} Custom{' '}
              {isMobile && <PlusIcon ml={{ _: '0.5rem', md: '1rem' }}/>}
            </Heading>
            <Text color='mvrkLightGray' fontSize='16px'>
              Looking from something bespoke or never been done before? Vx360 is your ultimate, virtual canvas. Together
              with our in-house creative and technical teams build your virtual venue and experience from the ground up.
              Requiring longer lead time, but offering a blue-sky opportunity to build something truly unique for you in
              look-and-feel and function. Speak with our team to further discuss the limitless possibilities.
            </Text>
          </Flex>
          <LinkButton alignSelf={{ md: 'flex-end' }} mt={{ _: '20px', md: 0 }} to='/contact'>
            Contact Us
          </LinkButton>
        </Flex>
      </Flex>
    </Flex>
  )
}
