import React, { useEffect } from 'react'

import { theme } from 'helpers'

export const useMobileDetect = () => {
  const [isMobile, setMobile] = React.useState(false)

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i))

    function handleResize() {
      if (window.innerWidth < theme?.screens?.sm) {
        if (!isMobile) {
          setMobile(true)
        }
      } else if (window.innerWidth > theme?.screens?.sm) {
        setMobile(false)
      }
    }

    window.addEventListener('resize', handleResize)

    if (mobile) {
      setMobile(mobile)
    } else {
      handleResize()
    }
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile }
}
