import React, { FC } from 'react'
import { camelCase } from 'lodash'

import { Flex, Heading, Text, RedNumber } from 'components'
import {
  ExtendedRealityImage,
  LaptopGamingImage,
  ModalsImage,
  MultiDeviceImage,
  Powerful3DImage,
  TabletMobileImage
} from 'staticImages'
import { x } from 'helpers'

interface HighlightProps {
  title?: string
  titleHtml?: string
  description: string
  highlightNumber?: string
  lightBackground?: boolean
  flipped?: boolean
  border?: boolean
}

const Images = {
  multiDevice: MultiDeviceImage,
  extendedReality: ExtendedRealityImage,
  powerful3D: Powerful3DImage,
  captivationThroughGaming: LaptopGamingImage,
  uniqueUserJourneys: ModalsImage,
  multiLayeredTouchpoints: TabletMobileImage
}

export const Highlight: FC<HighlightProps> = ({
  title,
  titleHtml,
  description,
  highlightNumber,
  lightBackground,
  flipped,
  border
}) => {
  const Image = Images[camelCase(title)]
  return (
    <x.section {...(lightBackground ? { bg: 'mvrkDarkGray' } : {})} px={{ _: '20px' }} width='100%'>
      <x.section
        className='max-content-width'
        px={{ _: '0px !important' }}
        m='0 auto'
        {...(border
          ? {
              borderBottom: '1px solid',
              borderImage:
                'linear-gradient(90deg, rgba(104, 116, 116, 0) 2.08%, #687474 19.27%, #687474 80.73%, rgba(104, 116, 116, 0) 97.92%) 1'
            }
          : {})}
      >
        <Flex
          minHeight={{ _: '483px', md: '510px' }}
          pt={{ _: '40px', md: '35px' }}
          pb='40px'
          justifyContent='center'
          alignItems='center'
          flexDirection={{ _: 'column', md: 'row' }}
        >
          <Flex
            flex={1}
            justifyContent='center'
            flexDirection='column'
            {...(flipped ? { order: { _: 2 } } : { order: { _: 2, md: 1 } })}
          >
            <Heading
              as='h3'
              fontSize={{ _: '24px', md: '36px' }}
              fontWeight='900'
              textTransform='uppercase'
              mb={{ _: '5px' }}
              mt={{ _: '30px', md: 0 }}
            >
              {highlightNumber && (
                <RedNumber fontWeight='700' mr={{ _: '10px', md: '15px' }}>
                  {highlightNumber}
                </RedNumber>
              )}
              {title && !titleHtml ? (
                title
              ) : titleHtml ? (
                <span dangerouslySetInnerHTML={{ __html: titleHtml }} />
              ) : null}
            </Heading>
            <Text fontSize={{ _: '14px', md: '16px' }} color='mvrkLightGray'>
              {description}
            </Text>
          </Flex>
          <Flex
            flex={1}
            alignItems='center'
            maxWidth='600px'
            {...(flipped ? { order: { _: 1 }, pr: { md: '50px' } } : { order: { _: 1, md: 2 }, pl: { md: '50px' } })}
          >
            <Image />
          </Flex>
        </Flex>
      </x.section>
    </x.section>
  )
}
