import React from 'react'
import { startCase } from 'lodash'

import { x } from 'helpers'
import { Flex } from '../layout'

export const Input = ({ col, error, containerStyles = {}, ...props }) => {
  return (
    <Flex flexDirection='column' col={col} {...containerStyles}>
      <x.label fontSize='14px' mb='4px' color={props.labelColor || '#EEE'}>
        {props.label || startCase(props.name)}
      </x.label>
      <x.input
        data-lpignore='true'
        h='50px'
        minHeight='50px'
        border='1px solid'
        borderColor='mvrkLightGray'
        fontSize='14px'
        pl='20px'
        outline={{ focus: 'none' }}
        color='mvrkLightGray'
        opacity={{ placeholder: '.25' }}
        {...props}
      />
      {error ? (
        <x.span fontSize='10px' pt='2px' color='error'>
          {error}
        </x.span>
      ) : null}
    </Flex>
  )
}
