import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const MobileMenuClose: FC<IconProps> = ({ width = '20', height = '20', fill = '#ffffff', ...props }) => {
  return (
    <x.svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill={{ _: fill, hover: '#38f4f1' }}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.839966 0.528398C1.54948 -0.176133 2.69982 -0.176133 3.40933 0.528398L7.04296 4.13652C7.75247 4.84105 7.75247 5.98332 7.04296 6.68785C6.33345 7.39238 5.18311 7.39238 4.4736 6.68785L0.839966 3.07973C0.130455 2.3752 0.130455 1.23293 0.839966 0.528398ZM0.532133 19.4716C-0.177378 18.7671 -0.177378 17.6248 0.532133 16.9203L16.5907 0.974465C17.3002 0.269935 18.4505 0.269934 19.16 0.974465C19.8695 1.679 19.8695 2.82126 19.16 3.52579L3.1015 19.4716C2.39199 20.1761 1.24164 20.1761 0.532133 19.4716ZM16.127 13.1568C15.4175 12.4523 14.2672 12.4523 13.5577 13.1568C12.8482 13.8614 12.8482 15.0036 13.5577 15.7082L16.8985 19.0256C17.608 19.7301 18.7584 19.7301 19.4679 19.0256C20.1774 18.3211 20.1774 17.1788 19.4679 16.4743L16.127 13.1568Z'
      />
    </x.svg>
  )
}
