import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const VideoPlayButtonIcon: FC<IconProps> = ({ size = 80, fill = '#111111', ...props }) => {
  return (
    <x.svg
      width={size}
      height={size}
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      display='inline'
      {...props}
    >
      <circle cx='40' cy='40' r='38' fill='#111111' fill-opacity='0.7' stroke='white' strokeWidth='4' />
      <path d='M52.8422 40L32.0001 52L32.0001 28L52.8422 40Z' fill='white' />
    </x.svg>
  )
}
