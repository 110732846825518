import React from 'react'

import { useCarousel } from 'hooks'
import { Flex, FeatureCard } from 'components'
import { x } from 'helpers'
import { useAppState } from 'providers'

export const Carousel = ({ cards }) => {
  const [active, jumpToSlide, { ref, ...handlers }, styles, isSwiping] = useCarousel(5, 260)
  const { setDrawerContent } = useAppState()

  return (
    <x.section overflow='hidden' position='relative'>
      <Flex
        flexWrap='nowrap'
        overflow='hidden'
        position='relative'
        w={260 * cards.length}
        {...styles}
        {...handlers}
        ref={ref}
      >
        {cards.map((card, i) => (
          <FeatureCard key={`card-${i}`} card={card} w='90%' {...(i !== cards.length - 1 ? { mr: '20px' } : {})} />
        ))}
      </Flex>
      <Flex w='112px' mx='auto' mt='20px'>
        {cards.map((_, i) => (
          <x.button
            key={`card-button-${i}`}
            w={active === i ? '22px' : '8px'}
            h='8px'
            border='1px solid white'
            borderRadius={active === i ? '8px' : '50%'}
            bg={active === i ? 'white' : 'transparent'}
            p='0'
            {...(i !== cards.length - 1 ? { mr: '10px' } : {})}
            transition='all 0.25s ease'
            outline={{ focus: 'none', focusVisible: 'none' }}
            onClick={() => jumpToSlide(i)}
          />
        ))}
      </Flex>
    </x.section>
  )
}
