import React, { FC } from 'react'
import { SystemProps } from '@xstyled/styled-components'

import { x } from 'helpers'

interface ButtonProps extends SystemProps {
  onClick?: () => void
  variant?: 'primary' | 'transparent'
  type?: string
  disabled?: boolean
}

export const Button: FC<ButtonProps> = ({ variant = 'primary', ...props }) => {
  const isPrimary = variant === 'primary'
  const isTransparent = variant === 'transparent'

  return (
    <x.button
      py={2}
      px={4}
      w={{ _: '130px', md: '168px' }}
      h={{ _: '40px', md: '45px' }}
      fontFamily='proxima'
      fontSize={{ _: '.75rem', lg: '0.875rem' }}
      fontWeight={600}
      textTransform='uppercase'
      color={{ _: props.color || isTransparent ? 'white' : 'black', hover: isTransparent ? 'primary' : 'initial' }}
      bg={variant}
      borderRadius={46}
      outline={{ focus: 'none' }}
      transition
      boxShadow={{ hover: isPrimary ? '0px 5px 30px rgba(56, 244, 241, 0.35)' : 'none' }}
      border={{ _: props.border || isTransparent ? '1px solid white' : 'none' }}
      borderColor={{ hover: isTransparent ? 'primary' : '' }}
      {...props}
    />
  )
}
