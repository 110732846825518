import React from 'react'
import { Splash } from 'components'

export const ExploreSplash = () => {
  return (
    <Splash
      alignment='top'
      desktopImg='https://d27pgjik7gkkwi.cloudfront.net/exploreSplash.png'
      mobileImg='https://d27pgjik7gkkwi.cloudfront.net/exploreSplashMobile.png'
      title1='Explore'
      title2='The platform'
      description='Offering the best in 3D environments for premium experiences, an all inclusive feature library, and a canvas of limitless possibility; Vx360 is built for revolutionary brand moments that amplify engagement opportunities.'
    />
  )
}
