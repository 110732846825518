import React, { FC } from 'react'

import { useCarousel } from 'hooks'
import { Flex } from 'components'
import { x, IGalleryImage } from 'helpers'

interface TemplateImageCarouselProps {
  images: IGalleryImage[]
  title: string
  onClick: (index: number) => void
}

export const TemplateImageCarousel: FC<TemplateImageCarouselProps> = ({ images, title, onClick }) => {
  const [_, __, { ref, ...handlers }, styles] = useCarousel(4, 142)

  return (
    <x.section overflow='hidden' position='relative'>
      <Flex
        flexWrap='nowrap'
        overflow='hidden'
        position='relative'
        w={260 * images.length}
        {...styles}
        {...handlers}
        ref={ref}
      >
        {images.map((image, index) => (
          <x.span key={`${title}-image-${index}`} cursor={{ hover: 'pointer' }} onClick={() => onClick(index)}>
            {image.thumbSrc}
          </x.span>
        ))}
      </Flex>
    </x.section>
  )
}
