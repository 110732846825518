import React, { FC } from 'react'

import { Carousel, ColorBars, FeatureCard, Flex, NumberedHeading } from 'components'
import { x, IFeatureCard } from 'helpers'
import { useAppState } from 'providers'
import { SystemProps } from '@xstyled/system'

interface FeatureCardSectionProps extends SystemProps {
  isMobile: boolean
  number: string
  title: string
  description: string
  featureCards: IFeatureCard[]
  pb?: string
  pt?: string
}

export const FeatureCardSection: FC<FeatureCardSectionProps> = ({
  isMobile,
  number,
  title,
  description,
  featureCards,
  ...props
}) => {
  const { setDrawerContent } = useAppState()
  return (
    <x.section width='100%' bg='mvrkDarkGray' {...props}>
      <x.section
        className='max-content-width'
        margin='0 auto'
        pt={{ _: props.pt || '102px' }}
        pb={{ _: '102px' }}
        pl={{ _: '20px', sm: '30px', md: '20px' }}
        pr={{ _: 0, sm: '30px', md: '20px' }}
      >
        <Flex position='absolute' right='0' h='100%' alignItems='center' display={{ _: 'none', sm: 'flex' }}>
          <ColorBars />
        </Flex>
        <Flex mb={{ _: '33px' }} alignItems='center' flexWrap={{ _: 'wrap', md: 'nowrap' }}>
          <NumberedHeading number={number} title={title}>
            {description}
          </NumberedHeading>
        </Flex>
        {isMobile ? (
          <Carousel cards={featureCards} />
        ) : (
          <Flex flexWrap='wrap'>
            {featureCards.map((card, i) => (
              <FeatureCard
                key={'card' + i}
                card={card}
                index={i}
              />
            ))}
          </Flex>
        )}
      </x.section>
    </x.section>
  )
}
