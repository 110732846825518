import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const ChevronRight: FC<IconProps> = ({ width = 10, height = 16, fill = '#818D8D', ...props }) => {
  return (
    <x.svg width={width} height={height} viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <x.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.57475 16L-6.86181e-08 14.4302L6.45049 8L-6.30764e-07 1.5698L1.57475 -6.88346e-08L9.6 8L1.57475 16Z'
        fill={fill}
      />
    </x.svg>
  )
}
