import React, { FC } from 'react'

import { x } from 'helpers'
import { CloseIcon } from '../icons/CloseIcon'

export const CloseButton = props => {
  return (
    <x.button
      w={'36px'}
      h={'36px'}
      bg='rgba(0,0,0,0.85)'
      display='flex'
      justifyContent='center'
      alignItems='center'
      outline={{ focus: 'none' }}
      ringColor={{ focus: 'transparent' }}
      position='absolute'
      top='10px'
      right='10px'
      zIndex={200}
      {...props}
    >
      <CloseIcon />
    </x.button>
  )
}
