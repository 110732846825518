import React, { FC } from 'react'
import { x } from 'helpers'

import { IconProps } from 'types'

export const MobileMenuOpen: FC<IconProps> = ({ height = 15, width = 20, fill = '#ffffff', ...props }) => {
  return (
    <x.svg
      width={width}
      height={height}
      viewBox='0 0 20 15'
      fill={{ _: fill, hover: '#38f4f1' }}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <x.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 1.37931C0 0.617538 0.617538 0 1.37931 0H3.02135C3.78312 0 4.40066 0.617538 4.40066 1.37931C4.40066 2.14108 3.78312 2.75862 3.02135 2.75862H1.37931C0.617538 2.75862 0 2.14108 0 1.37931ZM0 7.12646C0 6.36469 0.617538 5.74715 1.37931 5.74715H18.6207C19.3825 5.74715 20 6.36469 20 7.12646C20 7.88823 19.3825 8.50577 18.6207 8.50577H1.37931C0.617538 8.50577 0 7.88823 0 7.12646ZM8.76844 0C8.00667 0 7.38913 0.617538 7.38913 1.37931C7.38913 2.14108 8.00667 2.75862 8.76844 2.75862H18.6207C19.3824 2.75862 20 2.14108 20 1.37931C20 0.617538 19.3824 0 18.6207 0H8.76844ZM15.5993 12.8735C15.5993 12.1118 16.2168 11.4942 16.9786 11.4942H18.6207C19.3824 11.4942 20 12.1118 20 12.8735C20 13.6353 19.3824 14.2528 18.6207 14.2528H16.9786C16.2168 14.2528 15.5993 13.6353 15.5993 12.8735ZM1.37931 11.4942C0.617538 11.4942 0 12.1118 0 12.8735C0 13.6353 0.617538 14.2528 1.37931 14.2528H12.0525C12.8143 14.2528 13.4319 13.6353 13.4319 12.8735C13.4319 12.1118 12.8143 11.4942 12.0525 11.4942H1.37931Z'
      />
    </x.svg>
  )
}
