import { useEffect, useState, useCallback } from 'react'

import { createFormFieldConfig } from 'helpers'
import { IFormFieldObject } from 'types'

export const useForm = (initialValues, formObj, onSubmit) => {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [initialForm, setInitialForm] = useState<{ [key: string]: IFormFieldObject }>(formObj)
  const [form, setForm] = useState<{ [key: string]: IFormFieldObject }>(formObj)
  const [values, setValues] = useState<{ [key: string]: string | number | boolean }>(initialValues)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    Object.keys(formObj).map(
      key =>
        (formObj[key] = {
          ...createFormFieldConfig(key),
          ...formObj[key]
        })
    )
    setInitialForm(formObj)
    setForm(formObj)
  }, [])

  const resetForm = () => {
    setSubmitted(false)
    setValues(initialValues)
    setForm(initialForm)
    setErrors({})
    setTouched({})
  }

  const isInputFieldValid = useCallback(
    inputField => {
      let errorCount = 0
      for (const rule of inputField.validationRules) {
        if (errorCount === 0 && !rule.validate(inputField.value, form)) {
          errorCount++
          setErrors({
            ...errors,
            [inputField.label]: rule.message
          })
          return false
        }
      }

      if (errorCount === 0) {
        setErrors({
          ...errors,
          [inputField.label]: ''
        })
        return true
      }
    },
    [form]
  )

  const onInputChange = useCallback(
    event => {
      const { name, value } = event.target
      const inputObj = { ...form[name] }
      inputObj.value = value
      setValues({
        ...values,
        [name]: value
      })

      if (inputObj.validationRules) {
        const isValidInput = isInputFieldValid(inputObj)
        if (isValidInput && !inputObj.valid) {
          inputObj.valid = true
        } else if (!isValidInput && inputObj.valid) {
          inputObj.valid = false
        }
      }

      setTouched({
        ...touched,
        [name]: true
      })
      setForm({ ...form, [name]: inputObj })
    },
    [form, isInputFieldValid]
  )

  /**
   * returns boolean value indicating whether overall form is valid
   *
   * @param {object} formObj - object representation of a form
   */
  const isFormValid = useCallback(() => {
    let isValid = true
    const arr = Object.values(form)
    for (let i = 0; i < arr.length; i++) {
      if (!arr[i].valid) {
        isValid = false
        break
      }
    }

    return isValid
  }, [form])

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    let submitErrors = {}
    Object.keys(initialValues).map(key => {
      if (form[key]) {
        const inputField = form[key]
        for (const rule of inputField.validationRules) {
          if (!submitErrors[inputField.label] && !rule.validate(inputField.value, form)) {
            submitErrors = {
              ...submitErrors,
              [inputField.label]: rule.message
            }
          }
        }
      }
      touched[key] = true
    })

    setErrors(submitErrors)
    if (Object.keys(submitErrors).length > 0) return

    if (isFormValid()) {
      return onSubmit()
    }
  }

  return { onInputChange, isFormValid, errors, touched, values, submitted, setSubmitted, handleSubmit, resetForm }
}
