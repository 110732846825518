import React, { FC } from 'react'

import { Faq } from './Faq'
import { Flex, NumberedHeading } from 'components'
import { x } from 'helpers'
import { IFaq } from 'types'

interface FaqsProps {
  heading: {
    number: string
    title: string
    description?: string
  }
  faqs: IFaq[]
}

export const Faqs: FC<FaqsProps> = ({ heading, faqs }) => {
  return (
    <x.div my={{ xs: '3rem', md: '6rem' }}>
      <x.div px={{ xs: '1rem' }}>
        <NumberedHeading number={heading.number} title={heading.title} />
      </x.div>

      <Flex w='100%' flexWrap='wrap'>
        {faqs.map(faq => (
          <x.div w={{ xs: '100%', md: '50%' }} padding={{ xs: '1rem', md: '1rem' }}>
            <Faq question={faq.question} answer={faq.answer} />
            <x.div
              w='100%'
              mt='2rem'
              borderBottom='1px solid'
              borderImage='linear-gradient(90deg,rgba(104,116,116,0) 2.08%,#687474 19.27%,#687474 80.73%,rgba(104,116,116,0) 97.92%) 1'
            />
          </x.div>
        ))}
      </Flex>
    </x.div>
  )
}
