import { validateEmail, validatePhoneNumber } from './validate'

export const createFormFieldConfig = (label: string, defaultValue: string = '') => {
  return {
    label,
    value: defaultValue,
    valid: false,
    errorMessage: '',
    touched: false
  }
}

const createRule = (ruleName: string, errorMessage: string, validateFunc: (val: any, formObj?: any) => any) => ({
  name: ruleName,
  message: errorMessage,
  validate: validateFunc
})

export const requiredRule = (customMessage?: string) =>
  createRule('required', customMessage || `Required`, inputValue => inputValue.length !== 0)

export const emailRule = (customMessage?: string) =>
  createRule('email', customMessage || `Please enter a valid email`, inputValue => validateEmail(inputValue))

export const phoneRule = (customMessage?: string) =>
  createRule('phone', customMessage || `Please enter a valid phone number`, inputValue =>
    inputValue.length > 0 ? validatePhoneNumber(inputValue) : true
  )

export const minLengthRule = (inputName: string, minCharacters: number, customMessage?: string) =>
  createRule(
    'minLength',
    customMessage || `${inputName} should contain atleast ${minCharacters} characters`,
    inputValue => inputValue.length >= minCharacters
  )

export const maxLengthRule = (inputName: string, maxCharacters: number, customMessage?: string) =>
  createRule(
    'minLength',
    customMessage || `${inputName} cannot contain more than ${maxCharacters} characters`,
    inputValue => inputValue.length <= maxCharacters
  )
