import React, { FC } from 'react'
import { SystemProps } from '@xstyled/styled-components'

import { Button, Flex, Heading, RedNumber } from 'components'
import { DrawerOptions, DrawerTypes, x } from 'helpers'
import { PlayButtonIcon } from 'icons'
import { useAppState } from 'providers'

export const TemplatesContainer: FC<SystemProps> = ({ children, ...props }) => {
  return (
    <Flex justifyContent='center' pt='125px' pb='150px' column {...props} className='max-content-width' m='0 auto'>
      {children}
    </Flex>
  )
}

export const TemplatesContentContainer: FC = ({ children }) => {
  return (
    <Flex justifyContent='center' flexDirection={{ _: 'column', md: 'row' }}>
      {children}
    </Flex>
  )
}

export const TemplatesImagesContainer: FC = ({ children }) => {
  return (
    <Flex justifyContent='center' mt='80px'>
      {children}
    </Flex>
  )
}

interface TemplatesMediaContainerProps {
  reverse?: boolean
}

export const TemplatesMediaContainer: FC<TemplatesMediaContainerProps> = ({ reverse, children }) => {
  return (
    <Flex
      flex={1}
      w='100%'
      maxWidth='490px'
      m={{ _: '0 auto', md: 0 }}
      {...(reverse ? { order: { _: 1, md: 2 } } : {})}
    >
      {children}
    </Flex>
  )
}

interface TemplatesTextContainerProps {
  title: string
  titleNumber: string
  reverse?: boolean
  onPlayTour: () => void
}

export const TemplatesTextContainer: FC<TemplatesTextContainerProps> = ({
  title,
  titleNumber,
  reverse,
  children,
  onPlayTour
}) => {
  const { setDrawerContent } = useAppState()

  return (
    <Flex
      column
      flex={1}
      maxWidth={{ _: '100%', md: '490px' }}
      mt={{ _: '30px', md: 0 }}
      {...(reverse ? { mr: { _: 0, md: '54px' }, order: { _: 2, md: 1 } } : { ml: { _: 0, md: '54px' } })}
    >
      <Heading
        as='h3'
        fontSize={{ _: '24px', md: '48px' }}
        mt={{ _: 0, md: '-10px' }}
        display='flex'
        alignItems='center'
      >
        <RedNumber mr='16px'>{titleNumber}</RedNumber>{' '}
        <x.span fontSize='14px' mr='10px'>
          The
        </x.span>{' '}
        {title}
      </Heading>
      {children}
      <Flex pb={{ _: '40px', md: 0 }} mt='30px'>
        <Button mr={{ _: '20px' }} onClick={onPlayTour}>
          <Flex justifyContent='center' alignItems='center'>
            Play Tour <PlayButtonIcon ml={1} />
          </Flex>
        </Button>
        <Button variant='transparent' onClick={() => setDrawerContent(DrawerOptions[DrawerTypes.demoDrawer])}>
          Request Demo
        </Button>
      </Flex>
    </Flex>
  )
}
