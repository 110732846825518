import { IDrawerContent } from 'types'

export enum DrawerTypes {
  infoDrawer = 'infoDrawer',
  demoDrawer = 'demoDrawer'
}

export const DrawerOptions: { [key: string]: IDrawerContent } = {
  infoDrawer: {
    title: ''
  },
  demoDrawer: {
    title: 'Ready for a demo?'
  }
}
