import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { CustomImage } from '.'

export const ColorBarsHorizontal: FC<any> = ({ containerStyles = {}, ...props }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "color-bars-horizontal.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 130, height: 11, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <CustomImage
      image={data.file.childImageSharp.gatsbyImageData}
      alt='Color Bars'
      {...props}
      style={containerStyles}
    />
  )
}
