import React from 'react'

import { Highlight } from '../highlight'
import { x } from 'helpers'
import { Flex } from './Flex'
import { NumberedHeading } from 'components'

export const StandardHighlights = () => (
  <x.section width='100%' bg='mvrkDarkerGray'>
    <x.section
      className='max-content-width'
      px={{ _: '0px !important' }}
      margin='0 auto'
      pt={{ _: '102px', md: '102px' }}
    >
      <Flex
        flex={1}
        justifyContent='center'
        flexDirection='column'
        order={{ _: 2, md: 1 }}
        px={{ _: '20px' }}
        mb='-35px'
      >
        <NumberedHeading number='05' title='CREATE A NEW STANDARD'>
          Vx360 is an open canvas for meaningful engagement and experience. We don’t stop at core capabilities, but look
          to extend offerings that amplify. Explore custom offerings and opportunities that are available, and speak
          with our team today to talk about the limitless possibilities and emerging technologies available on the
          platform and for you.
        </NumberedHeading>
      </Flex>
      <Highlight
        title='Captivation Through Gaming'
        titleHtml='Captivation <br/>Through Gaming'
        description='Whether custom mini arcade games, trivia, or the use of gameplay mechanics— the ones that make games so fun and addictive such as time constraints and leaderboards—add-ons like such engage attendees, alter behavior, and achieve goals and objectives.'
        border
        flipped
      />
      <Highlight
        title='Unique User Journeys'
        description='Build a story into the experience with definitive start and end points with tasks along the way. Creating incentive and reward for completion support efforts to have continuous participation and attention to messaging, exclusive content, and drive timely attendance. Especially across multi-day events.'
        border
      />
      <Highlight
        title='Multi-layered touchpoints'
        description='Create multi-screen experiences with the use of emerging technologies and extended reality that build multi-dimensional and sensory narratives. Using AR, or networks of content and media, you can build deeper stories and hands-on experiences. Thinking in 360 and creating an IRL moment.'
        flipped
      />
    </x.section>
  </x.section>
)
