import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const PlayButtonIcon: FC<IconProps> = ({ size = 16, fill = '#111111', ...props }) => {
  return (
    <x.svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.6 14.4H14.4V1.6H1.6V14.4ZM0 16H16V0H0V16ZM5.6 9.91992V6.31992C5.6 5.51992 6.64 5.03992 7.28 5.51992L10.08 7.35996C10.64 7.75996 10.64 8.55996 10.08 8.95996L7.36 10.8C6.64 11.2 5.6 10.7199 5.6 9.91992Z'
        fill={fill}
      />
    </x.svg>
  )
}
