import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const PlusIcon: FC<IconProps> = ({ size = 12, fill = '#F1122D', ...props }) => {
  return (
    <x.svg
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      display='inline'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.85718 5.14288V0H4.85718V5.14288L0 5.14288V7.14288L4.85718 7.14288V12H6.85718V7.14288L12 7.14288V5.14288L6.85718 5.14288Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.85718 5.14288V0H4.85718V5.14288L0 5.14288V7.14288L4.85718 7.14288V12H6.85718V7.14288L12 7.14288V5.14288L6.85718 5.14288Z'
        fill={fill}
      />
    </x.svg>
  )
}
