import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export const DrawerHero: FC<any> = props => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "drawerHero.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  return <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt='Hero' {...props} />
}
