import React, { FC } from 'react'
import { TypographyProps, SpaceProps } from '@xstyled/styled-components'

import { x } from 'helpers'

interface NumberedHeadingProps extends TypographyProps, SpaceProps {
  number: string
  title: string
  the?: boolean
}

export const NumberedHeading: FC<NumberedHeadingProps> = ({ number, title, the, children, ...props }) => {
  return (
    <x.div mb={{ xs: '1rem', md: '1.5rem' }}>
      <x.h2
        fontSize={{ xs: '1.5rem', sm: '3rem' }}
        lineHeight={{ xs: '1.5rem', sm: '3rem' }}
        h='45px'
        display='flex'
        alignItems='center'
        mb={{ xs: '1rem', md: '1.5rem' }}
        {...props}
      >
        <x.span color='#F1122D' fontFamily='anonymous' mr={{ xs: '.5rem', md: '1rem' }} fontWeight='700'>
          {number}
        </x.span>
        {the && (
          <x.span
            textTransform='uppercase'
            color='#fff'
            fontFamily='nimbus'
            fontWeight='900'
            fontSize='.875rem'
            mr='.25rem'
          >
            The
          </x.span>
        )}
        <x.span textTransform='uppercase' color='#fff' fontFamily='nimbus' fontWeight='900' >
          {title}
        </x.span>
      </x.h2>
      {children && (
        <x.p fontSize={{ xs: '.875rem', md: '1rem' }} color='mvrkLightGray'>
          {children}
        </x.p>
      )}
    </x.div>
  )
}
