import React from 'react'
import { startCase } from 'lodash'

import { x } from 'helpers'
import { Flex } from '../layout'

export const TextArea = ({ containerStyles = {}, ...props }) => {
  return (
    <Flex flexDirection='column' w='100%' {...containerStyles}>
      <x.label fontSize='14px' mb='4px' color={props.labelColor || '#EEE'}>
        {props.label || startCase(props.name)}
      </x.label>
      <x.textarea
        w='100%'
        h='100px'
        minHeight='50px'
        border='1px solid'
        borderColor='mvrkLightGray'
        fontSize='14px'
        pt='15px'
        pl='20px'
        outline={{ focus: 'none' }}
        resize='none'
        color='mvrkLightGray'
        opacity={{ placeholder: '.25' }}
        {...props}
      />
    </Flex>
  )
}
