import React, { useState } from 'react'

import {
  Button,
  Heading,
  Text,
  ColorBars,
  MacDesktops,
  MacDesktopsMobile,
  OutlinedText,
  PlusIcon,
  MediaModal
} from 'components'
import { Flex } from './Flex'
import { useMobileDetect } from 'hooks'
import { useAppState } from 'providers'
import { DemoVideo, DrawerTypes, DrawerOptions } from 'helpers'

export const UniqueApproach = () => {
  const { isMobile } = useMobileDetect()
  const { setDrawerContent } = useAppState()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  return (
    <>
      <Flex pb={{ _: '30px', md: '100px' }} pt={{ _: '30px', md: '100px' }} position='relative' bg='mvrkDarkGray'>
        <Flex position='absolute' left='0' top='0' h='100%' alignItems='center' display={{ _: 'none', lg: 'flex' }}>
          <ColorBars />
        </Flex>
        <Flex className='max-content-width' m='0 auto' flexDirection={{ _: 'column', md: 'row' }}>
          <Flex flexDirection='column' flex={1} order={{ _: 2, md: 1 }}>
            <Heading color='primary' textTransform='uppercase' fontSize='14px' fontFamily='proxima' fontWeight='bold'>
              <Flex mb={{ _: '5px' }} mt={{ _: '10px', md: 0 }} alignItems='center'>
                <PlusIcon mr={{ _: '4px', md: '15px' }} />
                Vx360 3D Platform
              </Flex>
            </Heading>
            <Flex mb={{ _: '15px' }} flexDirection={{ _: 'column', md: 'row' }}>
              <OutlinedText
                textTransform='uppercase'
                fontSize={{ _: '36px', lg: '48px' }}
                fontFamily='nimbus'
                fontWeight='900'
                mr={{ md: '12px' }}
              >
                Unique
              </OutlinedText>
              <Heading
                textTransform='uppercase'
                fontSize={{ _: '36px', lg: '48px' }}
                fontFamily='nimbus'
                fontWeight='900'
              >
                Approach
              </Heading>
            </Flex>
            <Text color='mvrkLightGray' mb={{ _: '20px', md: '40px' }} fontSize={{ _: '14px', md: '16px' }}>
              Dreamed up by veteran agency creatives, strategists, and producers; and built by our team of developers,
              Vx360 is built on a user experience first mindset. All-inclusive modular features paired with fire-tested
              gaming tactics and event strategy, with custom, fully immersive or off the shelf templated environments
              drive results and interactions. Breathing new life into digital events and virtual experiences, the
              platform sets a new standard in reaching audiences on the web for conferences and experiential brand
              moments. Let's create the future, together.
            </Text>
            <Flex pb={{ _: '40px', md: 0 }}>
              <Button mr={{ _: '10px', md: '20px' }} onClick={() => setModalOpen(true)}>
                Play Video
              </Button>
              <Button variant='transparent' onClick={() => setDrawerContent(DrawerOptions[DrawerTypes.demoDrawer])}>
                Request Demo
              </Button>
            </Flex>
          </Flex>
          <Flex flex={1} pl={{ _: 0, md: '20px' }} order={{ _: 1, md: 2 }}>
            {isMobile ? (
              <MacDesktopsMobile
                containerStyles={{ width: '85%', height: 'auto', margin: '0 auto' }}
                objectFit='contain'
              />
            ) : (
              <MacDesktops containerStyles={{ width: '100%', height: 'auto', margin: '0 auto' }} objectFit='contain' />
            )}
          </Flex>
        </Flex>
      </Flex>

      <MediaModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        media={DemoVideo}
        mediaAspectRatio='16:9'
        showMediaOnly
        previousDisabled
        nextDisabled
      />
    </>
  )
}
