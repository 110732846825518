import React, { FC } from 'react'

import { x } from 'helpers'
import { IconProps } from 'types'

export const LinkedInIcon: FC<IconProps> = ({ width = 20, height = 23, fill = '#38F4F1', ...props }) => {
  return (
    <x.svg width={width} height={height} viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.5714 1.42725H1.42411C0.638393 1.42725 0 2.07387 0 2.86766V19.9654C0 20.7592 0.638393 21.4058 1.42411 21.4058H18.5714C19.3571 21.4058 20 20.7592 20 19.9654V2.86766C20 2.07387 19.3571 1.42725 18.5714 1.42725ZM6.04464 18.5517H3.08036V9.01731H6.04911V18.5517H6.04464ZM4.5625 7.71514C3.61161 7.71514 2.84375 6.94364 2.84375 5.99823C2.84375 5.05282 3.61161 4.28132 4.5625 4.28132C5.50893 4.28132 6.28125 5.05282 6.28125 5.99823C6.28125 6.9481 5.51339 7.71514 4.5625 7.71514ZM17.1562 18.5517H14.192V13.9138C14.192 12.8079 14.1696 11.3853 12.6518 11.3853C11.1071 11.3853 10.8705 12.5894 10.8705 13.8336V18.5517H7.90625V9.01731H10.75V10.3195H10.7902C11.1875 9.57029 12.1563 8.78096 13.5982 8.78096C16.5982 8.78096 17.1562 10.7565 17.1562 13.3252V18.5517Z'
        fill={fill}
      />
    </x.svg>
  )
}
