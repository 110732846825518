import React, { FC, useRef } from 'react'

import { Backdrop } from './Backdrop'
import { CloseButton } from './CloseButton'
import { DrawerHero } from '../staticImages'

import { x } from 'helpers'
import { useOnClickOutside } from 'hooks'
import { useAppState } from 'providers'

interface DrawerProps {
  side?: 'left' | 'right'
  hideBackdrop?: boolean
  animationTime?: string
}

export const Drawer: FC<DrawerProps> = ({ side = 'right', hideBackdrop, animationTime = '0.3', children }) => {
  const {
    appState: { drawerContent },
    setDrawerContent
  } = useAppState()
  const DrawerRef = useRef(null)

  const handleClickOutside = event => {
    if (!DrawerRef?.current?.contains(event.target)) {
      setDrawerContent(null)
    }
  }

  useOnClickOutside([DrawerRef], handleClickOutside)

  return (
    <Backdrop hideBackdrop={hideBackdrop} open={Boolean(drawerContent?.title)} animationTime={animationTime}>
      <x.div
        ref={DrawerRef}
        h={{ _: '100%', md: '100vh' }}
        backgroundColor='#fff'
        position='fixed'
        top='0'
        {...(side === 'right' ? { right: 0 } : { left: 0 })}
        w={{ _: '100%', md: '60%', lg: '50%', xl: '40%' }}
        maxWidth={{ _: '100%', md: '600px' }}
        zIndex='9991'
        transform={`translateX(${Boolean(drawerContent?.title) ? '0' : side === 'right' ? '100%' : '-100%'})`}
        transition={`transform ${animationTime}s ease-out`}
        overflow='auto'
      >
        <CloseButton onClick={() => setDrawerContent(null)} />
        <DrawerHero layout='fullWidth' />
        <x.section
          mt={{ _: '30px', md: '40px' }}
          mx={{ _: '20px', md: '40px' }}
          pb='80px'
          color='black'
          overflow='auto'
        >
          <x.h2
            mb={{ _: '20px', md: '30px' }}
            fontSize={{ _: '24px', md: '36px' }}
            fontFamily='nimbus'
            color='black'
            fontWeight={900}
            textTransform='uppercase'
          >
            {drawerContent?.title}
          </x.h2>
          {children}
        </x.section>
      </x.div>
    </Backdrop>
  )
}
