import React from 'react'
import { Highlight } from '../highlight'

export const Highlights = () => (
  <>
    <Highlight
      title='Multi-Device'
      description='Log-in and load the platform without any barrier to entry. Built with the latest 360 immersive web technology available, for desktop and mobile. There is no need to download an app, and running requires low computer memory footprint allowing for frictionless access, for all.'
      highlightNumber='01'
      lightBackground
    />
    <Highlight
      title='Powerful 3D'
      description='Creatively, the platform boasts fully-developed, high-fidelity, and immersive environments. Real, robust and dynamic; they transport users, event attendees, and customers into 360 degree, state-of-the-art walkable worlds that bring brands and events to life unlike others.'
      highlightNumber='02'
      flipped
    />
    <Highlight
      title='Extended Reality'
      description='Going beyond the screen, your virtual experience leaps off the screen. Built with the latest web technology, Vx360 brings IRL architecture, look and feel to screen, but also fingertips and headsets. Allowing for a more immersive and 1:1 experience between brand or company and customer or consumer.'
      highlightNumber='03'
      lightBackground
    />
  </>
)
