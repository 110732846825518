import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { IModalMedia } from 'types'

export interface IGalleryImage {
  height?: number
  width?: number
  thumbSrc: React.ReactNode
  modal: IModalMedia
}

export const HomeGalleryImages: IGalleryImage[] = [
  {
    height: 2,
    width: 2,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/demo-thumbnail.png'
        alt='MVRK Demo'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'The Vx360 Virtual Platform',
      description:
        'Immersive, web-based, device agnostic, and optimized for desktop and mobile use, the platform’s user experience boasts 360 degree exploration and walkability of a state-of-the-art event space that can be completely customized and lifelike in design.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/demo-thumbnail.png'
          alt='MVRK Demo'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 2,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/ms-thumbnail.png'
        alt='Masked Singer'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'FOX’s The Masked Singer',
      description:
        'This custom virtual replica of the Masked Singer set hosted the exclusive premiere of Season 4. The event included: 60 minutes of livestream content and 22 interactive modals.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/ms-thumbnail.png'
          alt='Masked Singer'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/ey-thumbnail.png'
        alt='EY Hologram Room'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Custom Environments',
      description:
        'Vx360 is your ultimate virtual canvas. Together with our in-house creative and technical teams, build your virtual venue and experience from the ground up. Explore our custom blue-sky capabilities.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/ey-thumbnail.png'
          alt='EY Hologram Room'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/demo2-thumbnail.png'
        alt='Vx360 Branded Demo'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Ready-to-go Templates',
      description:
        'Select from our four templated, signature environments that give you a head start on planning an experience that is both state-of-the-art and highly engaging. Featured: The Lightbox template.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/demo2-thumbnail.png'
          alt='Vx360 Branded Demo'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/car-thumbnail.png'
        alt='MVRK Racer'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Interaction: Custom Configuration',
      description:
        'Embedded touchpoints allow for user personalization and further exploration. Featured here is The Configurator function allowing for users to trigger information or animation through hotspots (“+”), or change conditions of a surrounding environment or presented model.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/car-thumbnail.png'
          alt='MVRK Racer'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/terminal-thumbnail.png'
        alt='Immersion Escape Room'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Verizon Media’s Immersion 2021',
      description:
        'Nestled inside Immersion, a two-day only digital event demonstrating the web and emerging creative technologies, attendees found Project Midnight, an anxiety-inducing and curiosity piquing timed multiplayer escape room.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/terminal-thumbnail.png'
          alt='Immersion Escape Room'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 2,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/vzdemo-thumbnail.png'
        alt='Verizon Vx360'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Verizon 5G Innovation Sessions',
      description:
        'This serial deployment of virtual events for Verizon Business Group, known as 5G Innovation Session, showcased customers how they can take advantage of the exciting and transformative benefits of Verizon’s 5G network.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/vzdemo-thumbnail.png'
          alt='Verizon Vx360'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/demo3-thumbnail.png'
        alt='Vx360 Branded Demo'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Ready-to-go Templates',
      description:
        'Select from our four templated, signature environments that give you a head start on planning an experience that is both state-of-the-art and highly engaging. Featured: The Foundry template.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/demo3-thumbnail.png'
          alt='Vx360 Branded Demo'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/techweek-thumbnail.png'
        alt='Techweek'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Vx360 Goes Beyond In-Person Experiences',
      description:
        'The platform offers limitless possibilities and revolutionizes virtual events, conferences, activations, and exhibits. Delivering meaningful messaging in a first of its kind way, creating a new standard in virtual events.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/techweek-thumbnail.png'
          alt='Techweek'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 2,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/buildit-thumbnail.png'
        alt='Verizon Build It'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {

      title: 'Verizon Media’s Build It: WFH Edition',
      description:
        'Initially intended as a physical event in April of 2020, we transformed Build It into a digital experience that welcomed 5,600+ users online. Fusing learning and gamification, it included: 14 Verizon Media brands, 42 education interactions, and 55 minutes of embedded content.',
      ignoreAspectRatio: true,
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/buildit-thumbnail.png'
          alt='Verizon Build It'
          objectFit='cover'
          style={{ height: '100%', width: '100%', display: 'block' }}
          imgStyle={{ height: 'inherit', width: '100%' }}
        />
      )
    }
  }
]

export const ExploreGallery: IGalleryImage[] = [
  {
    height: 1,
    width: 2,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/white-arch-2-thumbnail.png'
        alt='Immersion Demo'
        objectFit='cover'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Verizon Media’s Immersion 2021',
      description:
        'Immersion, a two-day digital-only event demonstrating web and emerging creative technologies. Featured is the event’s highly-conceptual avant garde style lobby. This custom environment was specially built for the experience and to represent the possibility of the future.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/white-arch-2-thumbnail.png'
          alt='Immersion Demo'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/demo-rectangle-thumbnail.png'
        objectFit='cover'
        alt='Custom Environment'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Custom Environments',
      description:
        'This custom environment captivated audiences through dynamic and interactive content that featured artistic 3D objects textured with uploads from photo feeds, scores from in-experience games, or event specific branding.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/demo-rectangle-thumbnail.png'
          alt='Custom Environment'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/arcade-thumbnail.png'
        alt='MVRK Arcade'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Limitless possibilities',
      description:
        'Vx360 is built for revolutionary brand moments that amplify engagement opportunities and your brand.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/arcade-thumbnail.png'
          alt='MVRK Arcade'
          objectFit='cover'
          objectPosition='center'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/auditorium-thumbnail.png'
        alt='Lightbox Template'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Ready-to-go templates',
      description:
        'Inspired by innovation centers and the gleaming titanium of state-of-the-art devices, our <strong><em>Lightbox</em></strong> template exudes a contemporary feel. Suggested use cases for this template include: conferences, meetings, video-forward and highly-visual programming',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/auditorium-thumbnail.png'
          alt='Lightbox Template'
          objectFit='cover'
          objectPosition='center'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 1,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/telcom-thumbnail.png'
        alt='Launchpad Template'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Deliver meaningful messaging',
      description:
        'Inspired by exhibit halls and blank-space venues, our <strong><em>Launchpad</em></strong> template is an intimate and focused environment. With 7-10 content areas throughout the space, this virtual environment is where you, your messaging, and your product or service are the main event.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/telcom-thumbnail.png'
          alt='Launchpad Template'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  },
  {
    height: 1,
    width: 2,
    thumbSrc: (
      <StaticImage
        src='https://d27pgjik7gkkwi.cloudfront.net/gallery/escape-attic-thumbnail.png'
        alt='Project Midnight'
        style={{ height: '100%', width: '100%' }}
      />
    ),
    modal: {
      title: 'Verizon Media’s Immersion 2021, Project Midnight',
      description:
        'Recognized by Babylon.js as an unconventional and revolutionary experience, Project Midnight, a multi-player virtual escape room leveraged 3D technology to create a stark resemblance to a high-end video game. This one-of-a-kind user experiences included 5 custom environments and 21 immersive puzzles to tell a haunting story.',
      image: (
        <StaticImage
          src='https://d27pgjik7gkkwi.cloudfront.net/gallery/escape-attic-thumbnail.png'
          alt='Project Midnight'
          objectFit='cover'
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ height: '100%', width: '100%' }}
        />
      )
    }
  }
]
