import React, { FC, useState } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { SystemProps } from '@xstyled/styled-components'

import { Flex } from 'components'
import { x } from 'helpers'
import { VideoPlayButtonIcon } from 'icons'

interface VideoProps extends ReactPlayerProps, SystemProps {
  containerStyles?: SystemProps
}

export const Video: FC<VideoProps> = ({ containerStyles = {}, ...props }) => {
  const [playing, setPlaying] = useState<boolean>(false)

  return (
    <x.section h='100%' position='relative' cursor={{ hover: 'pointer' }} {...containerStyles}>
      <ReactPlayer
        playing={playing}
        {...props}
        playIcon={<VideoPlayButtonIcon />}
        onClickPreview={() => setPlaying(true)}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        width='100%'
        height='100%'
        controls
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              controlsList: 'nodownload'
            }
          }
        }}
      />
    </x.section>
  )
}
