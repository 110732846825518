import React, { FC } from 'react'

import { Heading } from './Heading'
import { Flex } from '../layout'
import { PlusIcon } from '../icons'
import { OutlinedText } from './OutlinedText'
import { x } from 'helpers'

interface DividerHeadingProps {
  title1: string
  title2: string
}

export const DividerHeading: FC<DividerHeadingProps> = ({ title1, title2 }) => {
  return (
    <x.section
      position='relative'
      maxWidth={{ _: '100%' }}
      m='0 auto'
      px='20px'
      right='0'
      left='0'
      top={{ _: '-65px', md: '-90px', lg: '-100px' }}
      textAlign={{ sm: 'center', _: 'center' }}
    >
      <PlusIcon fill='#38F4F1' mt='-7px' mr={{ _: '4px', md: '15px' }} />
      <Flex my='10px' flexDirection='column'>
        <OutlinedText
          textTransform='uppercase'
          lineHeight={{ _: '36px', md: '64px', lg: '72px' }}
          fontSize={{ _: '36px', md: '64px', lg: '72px' }}
          fontFamily='nimbus'
          fontWeight='900'
          mr={{ md: '12px' }}
        >
          {title1}
        </OutlinedText>
        <Heading
          textTransform='uppercase'
          lineHeight={{ _: '36px', md: '64px', lg: '72px' }}
          fontSize={{ _: '36px', md: '64px', lg: '72px' }}
          fontFamily='nimbus'
          fontWeight='900'
        >
          {title2}
        </Heading>
      </Flex>
      <PlusIcon fill='#38F4F1' mt='2px' mr={{ _: '4px', md: '15px' }} />
    </x.section>
  )
}
